import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { ProjectStatus } from "~/areas/projects/components/ProjectStatus/ProjectStatus";
import type { ProjectCreatedOption } from "~/areas/projects/components/Projects/AddProject";
import AddProject from "~/areas/projects/components/Projects/AddProject";
import type { DoBusyTask } from "../DataBaseComponent";
import { GuidedSetupFrame } from "../OnboardingDialog/GuidedSetupFrame";
import addProjectImageDark from "./assets/img-addproject-dark.svg";
import addProjectImageLight from "./assets/img-addproject-light.svg";
interface CreateNewProjectProps {
    spaceId: string;
    projectGroupId?: string;
    next: (project: ProjectResource, option: ProjectCreatedOption) => void;
    back: () => void;
    close: () => void;
    doBusyTask: DoBusyTask;
}
function CreateNewProject(props: CreateNewProjectProps) {
    const onBackClicked = () => {
        props.back();
    };
    const onProjectCreated = (project: ProjectResource, option: ProjectCreatedOption) => {
        props.next(project, option);
    };
    const helpPanelContent = (<div>
            A <strong>project</strong> represents a specific software component, service, or database that you want to deploy and manage. Each project has its own deployment process.
        </div>);
    return (<GuidedSetupFrame helpPanelImage={{ src: addProjectImageLight, altText: "Add New Project", darkThemeSrc: addProjectImageDark }} helpPanelContent={helpPanelContent} onClose={props.close}>
            <div className={createNewProjectStyles.projectContainer}>
                <div className={createNewProjectStyles.project}>
                    <ProjectStatus projectSlug={undefined} spaceId={props.spaceId} doBusyTask={props.doBusyTask} hideCloseButton hideTitle flexibleWidth/>

                    <AddProject spaceId={props.spaceId} projectCreated={onProjectCreated} hideEnvironmentConfigWarning={true} projectNameTextBoxLabel="Project name" groupId={props.projectGroupId} title="Add New Project" saveButtonLable="Next" cancelButtonLabel="Back" onCancelled={onBackClicked} isKubernetesGuidedSetup={true}/>
                </div>
            </div>
        </GuidedSetupFrame>);
}
const createNewProjectStyles = {
    projectContainer: css({
        flexBasis: "100%",
        "& .MuiDialogContent-root, & .MuiDialogActions-root, & .MuiDialogTitle-root .MuiTypography-root div": {
            padding: "0 !important",
        },
        "& .MuiDialogContent-root div[role='alert'] + div": {
            marginTop: space["32"],
        },
        "& .MuiDialogTitle-root .MuiTypography-root": {
            padding: `${space["48"]} 0 ${space["32"]} 0 !important`,
            "& .MuiLinearProgress-root[role='progressbar']": {
                display: "none",
            },
        },
    }),
    project: css({
        paddingTop: space["40"],
    }),
};
export default CreateNewProject;
