import { Environment } from "@octopusdeploy/utilities";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import { useUIOnlyFeatureFlag } from "~/hooks/useUIOnlyFeatureFlag";
import { useIsVerticalNavigationEnabled } from "./useIsVerticalNavigationEnabled";
export function useIsDashboardVNextEnabled(): boolean {
    const { isFeatureEnabled } = useDashboardVNextFeature();
    return isFeatureEnabled;
}
export function useDashboardVNextFeature() {
    const isVerticalNavigationEnabled = useIsVerticalNavigationEnabled();
    const [isDashboardVNextDisabled, setIsDashboardVNextDiasbled] = useUIOnlyFeatureFlag("Disable DashboardVNext", { isInitiallyEnabled: false });
    const isOctopusFeatureToggleEnabled = useOctopusFeatureToggle("dashboard-vnext", false);
    const isFeatureEnabled = isVerticalNavigationEnabled && !isDashboardVNextDisabled && (Environment.isInDevelopmentMode() || isOctopusFeatureToggleEnabled);
    const showUIFeatureFlagToggle = isVerticalNavigationEnabled && !isOctopusFeatureToggleEnabled;
    return {
        isFeatureEnabled,
        isUIFeatureFlagEnabled: isDashboardVNextDisabled,
        showUIFeatureFlagToggle,
        setUIFeatureFlagEnabled: setIsDashboardVNextDiasbled,
    };
}
