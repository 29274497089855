import { css, cx } from "@emotion/css";
import { Avatar, Tooltip, UnavailableIcon, useIsHovered, useIsLargerThanIpadResolution, useIsMobile } from "@octopusdeploy/design-system-components";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { DetailedDashboardProject, InitialDashboardProject } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { useRef } from "react";
import * as React from "react";
import { deploymentCellStyles } from "~/areas/dashboard/DashboardOverview/DashboardProjectGroups";
import type { FavouriteProjectsData } from "~/areas/projects/components/DashboardDataSource/DataSet";
import { ProjectFavouriteIconButton, useOptimisticIsFavourite } from "~/areas/projects/components/ProjectLayout/ProjectFavouriteIconButton";
import { Skeleton } from "~/areas/projects/components/Projects/Skeleton";
import { repository } from "~/clientInstance";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
interface ProjectsDashboardRowHeaderProps {
    favouriteProjectsData: FavouriteProjectsData;
    project: DetailedDashboardProject | InitialDashboardProject;
}
export function ProjectsDashboardRowHeaderContent({ favouriteProjectsData, project }: ProjectsDashboardRowHeaderProps) {
    const isLargerThanIpad = useIsLargerThanIpadResolution();
    const isFavourite = favouriteProjectsData.favouriteProjects.find((p) => p.Id === project.Id) !== undefined;
    const setIsFavourite = (isFavourite: boolean) => favouriteProjectsData.onFavouriteProjectChanged(project.Id, isFavourite);
    return (<div className={cx(projectRowHeaderStyles, { [projectRowHeaderMobileStyles]: !isLargerThanIpad })}>
            <ProjectLink key={project.Id} project={project} isFavourite={isFavourite} setIsFavourite={setIsFavourite}/>
        </div>);
}
export function ProjectsDashboardRowHeaderSkeleton() {
    const isLargerThanIpad = useIsLargerThanIpadResolution();
    return (<td className={cx(projectRowHeaderStyles, { [projectRowHeaderMobileStyles]: !isLargerThanIpad })}>
            <ProjectLinkSkeleton />
        </td>);
}
const projectRowHeaderStyles = css({
    display: "flex",
    flexDirection: "column",
    width: "18.625rem",
    minWidth: "18.625rem",
    textAlign: "start",
});
const projectRowHeaderMobileStyles = css({
    width: "12rem",
    minWidth: "12rem",
});
interface ProjectLinkProps {
    isFavourite: boolean;
    setIsFavourite: (isFavourite: boolean) => Promise<boolean>;
    project: DetailedDashboardProject | InitialDashboardProject;
}
function ProjectLink({ project, setIsFavourite, isFavourite }: ProjectLinkProps) {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const isHovered = useIsHovered(containerRef);
    const getIsFavouriteButtonVisible = (isFavourite: boolean) => isFavourite || isHovered;
    const { Name, ProjectLogo, Id, IsDisabled } = project;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const projectUrl = links.projectRootRedirect.generateUrl({ spaceId: repository.spaceId!, projectSlug: Id });
    return (<div ref={containerRef} className={cx(deploymentCellStyles, projectLinkRootStyles)}>
            <ProjectLinkFavouriteButton isFavourite={isFavourite} setIsFavourite={setIsFavourite} getIsVisible={getIsFavouriteButtonVisible} projectName={Name}/>
            <InternalLink className={projectLinkStyles} to={projectUrl}>
                <div className={projectAvatarWrapperStyles}>
                    <div className={cx({ [projectAvatarWrapperDisabledStyles]: IsDisabled })}>
                        <Avatar shape={"squared"} src={ProjectLogo} size={36} alt={`${Name} Logo`}/>
                    </div>
                    {IsDisabled && (<div className={unavailableIconContainerStyles}>
                            <Tooltip content="Disabled" display="block">
                                <UnavailableIcon />
                            </Tooltip>
                        </div>)}
                </div>
                <div>
                    <span className={cx(projectNameStyles, { [projectNameDisabledStyles]: IsDisabled })}>{Name}</span>
                </div>
            </InternalLink>
        </div>);
}
function ProjectLinkSkeleton() {
    const isMobile = useIsMobile();
    return (<div className={cx(deploymentCellStyles, projectLinkRootStyles)}>
            <div className={favouriteButtonPlaceholderStyles}/>
            <div className={avatarSkeletonStyles}>
                <Skeleton shape={"Rounded"} borderRadius={"small"}/>
            </div>
            <span className={cx(projectNameSkeletonStyles, { [projectNameSkeletonMobileStyles]: isMobile })}>
                <Skeleton shape={"Rounded"} borderRadius={"small"}/>
            </span>
        </div>);
}
const favouriteButtonPlaceholderStyles = css({
    height: "1.25rem",
    width: "1.25rem",
});
const avatarSkeletonStyles = css({
    height: "2.25rem",
    width: "2.25rem",
});
const projectNameSkeletonStyles = css({
    height: "1.25rem",
    width: "10.75rem",
});
const projectNameSkeletonMobileStyles = css({
    width: "5rem",
});
const projectLinkRootStyles = css({
    display: "flex",
    gap: space["12"],
    alignItems: "center",
    padding: `1.125rem ${space["16"]}`,
});
const projectLinkStyles = css({
    display: "flex",
    gap: space["12"],
    alignItems: "center",
    font: text.interface.body.bold.medium,
    color: `${themeTokens.color.text.primary} !important`,
    flex: 1,
    "&:hover": {
        span: {
            color: themeTokens.color.text.link.default,
        },
    },
});
const projectAvatarWrapperStyles = css({
    position: "relative",
    alignSelf: "center",
});
const unavailableIconContainerStyles = css({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: themeTokens.color.icon.primary,
});
const projectAvatarWrapperDisabledStyles = css({
    WebkitFilter: "grayscale(1)",
    filter: "grayscale(1)",
    opacity: 0.4,
});
const projectNameStyles = css({
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-word",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
});
const projectNameDisabledStyles = css({
    color: themeTokens.color.text.disabled,
});
interface ProjectLinkFavouriteButtonProps {
    isFavourite: boolean;
    setIsFavourite: (isFavourite: boolean) => Promise<boolean>;
    getIsVisible: (isFavourite: boolean) => boolean;
    projectName: string;
}
function ProjectLinkFavouriteButton({ isFavourite, setIsFavourite, projectName, getIsVisible }: ProjectLinkFavouriteButtonProps) {
    const { optimisticIsFavourite, optimisticSetIsFavourite, isPendingUpdate } = useOptimisticIsFavourite(isFavourite, setIsFavourite);
    return (<div className={cx(projectLinkFavouriteButtonStyle.favouriteButtonContainer.root, getIsVisible(optimisticIsFavourite) ? projectLinkFavouriteButtonStyle.favouriteButtonContainer.visible : projectLinkFavouriteButtonStyle.favouriteButtonContainer.hidden)}>
            <ProjectFavouriteIconButton isFavourite={optimisticIsFavourite} setIsFavourite={optimisticSetIsFavourite} isDisabled={isPendingUpdate} projectName={projectName}/>
        </div>);
}
const projectLinkFavouriteButtonStyle = {
    favouriteButtonContainer: {
        root: css({
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
        }),
        hidden: css({
            visibility: "hidden",
        }),
        visible: css({
            visibility: "visible",
        }),
    },
};
