import { Checkbox } from "@octopusdeploy/design-system-components";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import { usePage } from "@octopusdeploy/portal-routes";
import React, { useState } from "react";
import { ProjectAnalyticView } from "~/analytics/Analytics";
import { useAlwaysHideOnboardingQuestionnaire } from "~/areas/projects/components/Projects/OnboardingQuestionnaire/AlwaysHideOnboardingQuestionnaire";
import { createCancelProjectSurveyAnalyticsEvent, createDismissProjectSurvey, createSubmitProjectSurveyAnalyticsEvent } from "~/areas/projects/components/Projects/OnboardingQuestionnaire/QuestionnaireAnalytics";
import QuestionnaireDialogLayout from "~/areas/projects/components/Projects/OnboardingQuestionnaire/QuestionnaireDialogLayout";
import styles from "~/areas/projects/components/Projects/OnboardingQuestionnaire/style.module.less";
import { useProjectContext } from "~/areas/projects/context/index";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent/index";
import { DataBaseComponent, useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import { Select, Text } from "~/components/form/index";
import type { ApplicationTypeOption, BuildTypeOption, DeploymentLocationOption, PackageLocationOption } from "./QuestionnaireAnswers";
import { applicationTypeOptions, buildTypeOptions, deploymentLocationOptions, packageLocationOptions } from "./QuestionnaireAnswers";
type OnboardingQuestionnaireAnswers = {
    applicationType: ApplicationTypeOption | undefined;
    otherApplicationType: string | undefined;
    buildTool: BuildTypeOption | undefined;
    otherBuildTool: string | undefined;
    packageLocation: PackageLocationOption | undefined;
    otherPackageLocation: string | undefined;
    deploymentLocation: DeploymentLocationOption | undefined;
    otherDeploymentLocation: string | undefined;
    runbookOnly: boolean | undefined;
};
interface OnboardingQuestionnaireDialogLayoutProps {
    close: () => void;
}
export class OnboardingQuestionnaireDialogLayout extends DataBaseComponent<OnboardingQuestionnaireDialogLayoutProps> {
    constructor(props: OnboardingQuestionnaireDialogLayoutProps) {
        super(props);
        this.state = {};
    }
    render() {
        return <OnboardingQuestionnaireDialogLayoutInternal close={this.props.close} doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors}/>;
    }
    static displayName = "OnboardingQuestionnaireDialogLayout";
}
interface OnboardingQuestionnaireDialogLayoutInternalProps {
    close: () => void;
    busy?: Promise<void>;
    doBusyTask: DoBusyTask;
    errors?: Errors;
}
function OnboardingQuestionnaireDialogLayoutInternal(props: OnboardingQuestionnaireDialogLayoutInternalProps) {
    const project = useProjectContext();
    const page = usePage();
    const trackEvent = useTrackEvent();
    const [alwaysHideOnboardingQuestionnaire, setAlwaysHideOnboardingQuestionnaire] = useAlwaysHideOnboardingQuestionnaire();
    const [submitting, setSubmitting] = useState(false);
    const [answers, setAnswers] = useState<OnboardingQuestionnaireAnswers>({
        applicationType: undefined,
        buildTool: undefined,
        deploymentLocation: undefined,
        packageLocation: undefined,
        runbookOnly: undefined,
        otherApplicationType: undefined,
        otherBuildTool: undefined,
        otherDeploymentLocation: undefined,
        otherPackageLocation: undefined,
    });
    useDoBusyTaskEffect(props.doBusyTask, async () => {
        if (!submitting)
            return;
        setSubmitting(false);
        const data = {
            technologyStack: answers.otherApplicationType ?? answers.applicationType,
            buildServer: answers.otherBuildTool ?? answers.buildTool,
            packageHost: answers.otherPackageLocation ?? answers.packageLocation,
            hostingPlatform: answers.otherDeploymentLocation ?? answers.deploymentLocation,
            runbookOnly: answers.runbookOnly === undefined ? undefined : answers.runbookOnly ? "True" : "False",
        };
        await repository.Projects.setProjectIntents(project.state.model.Id, project.state.model.SpaceId, {
            SpaceId: project.state.model.SpaceId,
            ProjectId: project.state.model.Id,
            ApplicationType: data.technologyStack,
            BuildServer: data.buildServer,
            PackageLocation: data.packageHost,
            DeploymentLocation: data.hostingPlatform,
            RunbookOnly: data.runbookOnly === "True" ? true : data.runbookOnly === "False" ? false : undefined,
        });
        props.close();
    }, [answers, submitting]);
    const dontShowAgainProps = {
        ["additionalActions"]: (<Checkbox key={"DontShowAgain"} className={styles.dontShowAgain} value={alwaysHideOnboardingQuestionnaire} onChange={(x) => {
                setAlwaysHideOnboardingQuestionnaire(x);
                if (x) {
                    dontShowAgainAction();
                }
            }} label={"Don't show this questionnaire again"}/>),
    };
    const dontShowAgainAction = () => {
        trackEvent(createDismissProjectSurvey(page));
    };
    const submitSurvey = async () => {
        setSubmitting(true);
        trackEvent(createSubmitProjectSurveyAnalyticsEvent(page, {
            technologyStack: answers.otherApplicationType ?? answers.applicationType,
            buildServer: answers.otherBuildTool ?? answers.buildTool,
            packageHost: answers.otherPackageLocation ?? answers.packageLocation,
            hostingPlatform: answers.otherDeploymentLocation ?? answers.deploymentLocation,
            runbookOnly: answers.runbookOnly,
        }));
        return true;
    };
    return (<>
            <ProjectAnalyticView name="View project survey" resource="Survey" projectId={project.state.model.Id}/>
            <QuestionnaireDialogLayout {...dontShowAgainProps} title="How do you intend to use this project?" saveButtonLabel="Submit" cancelButtonLabel="Skip" onSaveClick={submitSurvey} busy={props.busy} errors={props.errors} onCancelClick={() => {
            trackEvent(createCancelProjectSurveyAnalyticsEvent(page));
            props.close();
            return true;
        }}>
                <div className={styles.questionInfo}>We're looking to learn more about the projects our customers are creating to help us guide the direction of Octopus.</div>
                <div className={styles.question}>
                    <Select label="What type of application do you intend to deploy?" value={answers.applicationType || ""} sortItems={false} items={applicationTypeOptions.map((op) => ({ value: op, text: op }))} 
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    onChange={(x) => setAnswers({ ...answers, applicationType: x as ApplicationTypeOption, otherApplicationType: undefined })}/>
                    {answers.applicationType === "Other (please specify)" ? <Text value={answers.otherApplicationType ?? ""} onChange={(x) => setAnswers({ ...answers, otherApplicationType: x })} label="Other application type"/> : null}
                </div>
                <div className={styles.question}>
                    <Select label="Where do you build your application?" value={answers.buildTool || ""} sortItems={false} items={buildTypeOptions.map((op) => ({ value: op, text: op }))} 
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    onChange={(x) => setAnswers({ ...answers, buildTool: x as BuildTypeOption, otherBuildTool: undefined })}/>
                    {answers.buildTool === "Other (please specify)" ? <Text value={answers.otherBuildTool ?? ""} onChange={(x) => setAnswers({ ...answers, otherBuildTool: x })} label="Other build server"/> : null}
                </div>
                <div className={styles.question}>
                    <Select label="Where is your packaged application stored?" value={answers.packageLocation || ""} sortItems={false} items={packageLocationOptions.map((op) => ({ value: op, text: op }))} 
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    onChange={(x) => setAnswers({ ...answers, packageLocation: x as PackageLocationOption, otherPackageLocation: undefined })}/>
                    {answers.packageLocation === "Other (please specify)" ? <Text value={answers.otherPackageLocation ?? ""} onChange={(x) => setAnswers({ ...answers, otherPackageLocation: x })} label="Other repository"/> : null}
                </div>
                <div className={styles.question}>
                    <Select label="Where will your application be deployed?" value={answers.deploymentLocation || ""} sortItems={false} items={deploymentLocationOptions.map((op) => ({ value: op, text: op }))} 
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    onChange={(x) => setAnswers({ ...answers, deploymentLocation: x as DeploymentLocationOption, otherDeploymentLocation: undefined })}/>
                    {answers.deploymentLocation === "Other (please specify)" ? <Text value={answers.otherDeploymentLocation ?? ""} onChange={(x) => setAnswers({ ...answers, otherDeploymentLocation: x })} label="Other host"/> : null}
                </div>
                <Checkbox value={answers.runbookOnly ?? false} onChange={(x) => setAnswers({ ...answers, runbookOnly: x })} label="This is a Runbook-only project"/>
            </QuestionnaireDialogLayout>
        </>);
}
