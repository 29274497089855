import { CircularProgress, GreenTickIcon, TriangleExclamationIcon } from "@octopusdeploy/design-system-components";
import { MachineModelHealthStatus } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import styles from "./DiscoveryProgress.module.less";
type DiscoveryProgressProps = React.PropsWithChildren<{
    name: string;
    isFound: boolean;
    healthStatus: MachineModelHealthStatus;
    showHelpLink?: boolean;
}>;
export const DiscoveryProgress = ({ showHelpLink = true, ...props }: DiscoveryProgressProps) => {
    let boxStyle = styles.none;
    let content: JSX.Element = <></>;
    if (props.healthStatus !== MachineModelHealthStatus.Unknown) {
        if (props.healthStatus === MachineModelHealthStatus.Unhealthy || props.healthStatus === MachineModelHealthStatus.Unavailable) {
            boxStyle = styles.failure;
            content = (<>
                    <div className={styles.iconContainer}>
                        <TriangleExclamationIcon size={20} color={"danger"}/>
                    </div>
                    <span>
                        Health check failed for <b>{props.name}</b>
                        {showHelpLink && (<>
                                . Need help? <ExternalLink href={"kubernetes-agent"}>Read the docs</ExternalLink>
                            </>)}
                    </span>
                </>);
        }
        else {
            boxStyle = styles.success;
            content = (<>
                    <div className={styles.iconContainer}>
                        <GreenTickIcon />
                    </div>

                    <span>
                        Successful registration and health check for <b>{props.name}</b>
                    </span>
                </>);
        }
    }
    else {
        boxStyle = styles.waiting;
        content = (<>
                <div className={styles.waitingIcon}>
                    <CircularProgress size="container" aria-hidden="true"/>
                </div>
                {props.isFound ? (<span>
                        Performing health check on <b>{props.name}</b>
                    </span>) : (<span>
                        Waiting for <b>{props.name}</b> to establish a connection...
                    </span>)}
            </>);
    }
    return (<div className={boxStyle} role="alert">
            <div className={styles.contentContainer}>{content}</div>
            {props.children && <div>{props.children}</div>}
        </div>);
};
DiscoveryProgress.displayName = "TentacleDiscoveryProgress";
