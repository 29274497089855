import { css } from "@emotion/css";
import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { ActionButtonType } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { BlueprintResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { useState } from "react";
import { AddProcessBlueprintDialog } from "~/areas/blueprints/AddProcessBlueprintDialog";
import DeleteBlueprint from "~/areas/blueprints/DeleteBlueprint";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import { DataBaseComponent, useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import OnboardingPage from "~/components/GettingStarted/OnboardingPage";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import InternalLink from "~/components/Navigation/InternalLink/index";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { DataTable, DataTableBody, DataTableHeader, DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
interface BlueprintsPageInternalProps {
    doBusyTask: DoBusyTask;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
}
export function BlueprintsPageInternal({ busy, doBusyTask, errors }: BlueprintsPageInternalProps) {
    const addProcessBlueprintAction: PrimaryPageAction = {
        type: "custom",
        key: "Add Process Blueprint",
        hasPermissions: true,
        content: <AddProcessBlueprintButton />,
    };
    const [blueprints, setBlueprints] = useState<BlueprintResource[]>();
    const [spaceNames, setSpaceNames] = useState<Map<string, string>>();
    const [spaceIds, setSpaceIds] = useState<Map<string, string>>();
    const reload = useDoBusyTaskEffect(doBusyTask, async () => {
        const { blueprintResources, spaceNames, spaceIds } = await loadBlueprintsAndSpaces();
        setBlueprints(blueprintResources);
        setSpaceNames(spaceNames);
        setSpaceIds(spaceIds);
    }, []);
    const isLoading = !blueprints;
    return (<PaperLayoutVNext title="Process Blueprints" busy={busy} errors={errors} primaryAction={addProcessBlueprintAction}>
            <>
                {isLoading ? null : blueprints.length === 0 ? (<BlueprintsOnboarding />) : (<DataTable>
                        <DataTableHeader>
                            <DataTableRow>
                                <DataTableHeaderColumn>Name</DataTableHeaderColumn>
                                <DataTableHeaderColumn>Repository</DataTableHeaderColumn>
                                <DataTableHeaderColumn>Description</DataTableHeaderColumn>
                                <DataTableHeaderColumn>Space</DataTableHeaderColumn>
                            </DataTableRow>
                        </DataTableHeader>
                        <DataTableBody>
                            {blueprints.map((blueprint) => (<BlueprintRow blueprint={blueprint} spaceNames={spaceNames} spaceIds={spaceIds} reload={reload} key={blueprint.Id}/>))}
                        </DataTableBody>
                    </DataTable>)}
            </>
        </PaperLayoutVNext>);
}
//TODO put correct link to blueprints docs in callout
const BlueprintsOnboarding = () => (<OnboardingPage title="Add your first Blueprint to get started!" intro="Process Blueprints are a group of steps that are reusable for any project within this space." learnMore={<ExternalLink href="">Learn more</ExternalLink>}/>);
interface BlueprintRowProps {
    blueprint: BlueprintResource;
    spaceNames: Map<string, string> | undefined;
    spaceIds: Map<string, string> | undefined;
    reload: () => Promise<boolean>;
}
function BlueprintRow({ blueprint, spaceNames, spaceIds, reload }: BlueprintRowProps) {
    return (<DataTableRow className={styles.middle}>
            <DataTableRowColumn className={styles.middle}>
                <div className={styles.rowCellPadding}>
                    <InternalLink to={links.editBlueprintPage.generateUrl({ spaceId: spaceIds?.get(blueprint.ProjectId) ?? "", blueprintId: blueprint.Id })}>{blueprint.Name}</InternalLink>
                </div>
            </DataTableRowColumn>
            <DataTableRowColumn className={styles.middle}>
                <div className={styles.rowCellPadding}>{blueprint.RepositoryName}</div>
            </DataTableRowColumn>
            <DataTableRowColumn className={styles.middle}>
                <div className={styles.rowCellPadding}>{blueprint.Description}</div>
            </DataTableRowColumn>
            <DataTableRowColumn className={styles.middle}>
                <div className={styles.rowCellPadding}>{spaceNames ? spaceNames.get(blueprint.ProjectId) : ""}</div>
            </DataTableRowColumn>
            <DataTableRowColumn className={styles.right}>
                <div className={styles.rowCellPadding}>
                    <BlueprintOverflowMenu blueprint={blueprint} reload={reload}/>
                </div>
            </DataTableRowColumn>
        </DataTableRow>);
}
interface BlueprintOverflowProps {
    blueprint: BlueprintResource;
    reload: () => Promise<boolean>;
}
function BlueprintOverflowMenu({ blueprint, reload }: BlueprintOverflowProps) {
    const overFlowActions: Array<MenuItem | MenuItem[]> = [];
    overFlowActions.push(OverflowMenuItems.deleteItem("Delete", "Are you sure you want to delete this blueprint?", () => deleteBlueprint(blueprint, reload), <DeleteBlueprint blueprintName={blueprint.Name}/>));
    return <OverflowMenu menuItems={overFlowActions}/>;
}
async function deleteBlueprint(blueprint: BlueprintResource, reload: () => Promise<boolean>) {
    await repository.Blueprints.del({ Id: blueprint.Id, ChangeDescription: `Delete process blueprint '${blueprint.Name}'` });
    return await reload();
}
async function loadBlueprintsAndSpaces() {
    const spaces = await repository.Spaces.all();
    const blueprintResources: BlueprintResource[] = [];
    const spaceNames = new Map<string, string>();
    const spaceIds = new Map<string, string>();
    for (const space of spaces) {
        const scopedRepository = await repository.forSpace(space.Id);
        const response = await scopedRepository.Blueprints.all();
        for (const blueprint of response) {
            blueprintResources.push(blueprint);
            spaceNames.set(blueprint.ProjectId, space.Name);
            spaceIds.set(blueprint.ProjectId, space.Id);
        }
    }
    return { blueprintResources, spaceNames, spaceIds };
}
function AddProcessBlueprintButton() {
    return (<OpenDialogButton disabled={false} label={"Add Process Blueprint"} title={"Create new process blueprint"} type={ActionButtonType.Primary}>
            <AddProcessBlueprintDialog />
        </OpenDialogButton>);
}
const styles = {
    rowCellPadding: css({ paddingLeft: space[1] }),
    middle: css({
        verticalAlign: "middle !important",
    }),
    right: css({
        verticalAlign: "middle !important",
        float: "right",
    }),
};
export class BlueprintsPage extends DataBaseComponent<{}> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }
    render() {
        return <BlueprintsPageInternal busy={this.state.busy} doBusyTask={this.doBusyTask} errors={this.errors}></BlueprintsPageInternal>;
    }
    static displayName = "BlueprintsPage";
}
