import { css, cx } from "@emotion/css";
import { SearchInput, useDebouncedCallback } from "@octopusdeploy/design-system-components";
import * as React from "react";
import { Skeleton } from "~/areas/projects/components/Projects/Skeleton";
interface ProjectDashboardSearchBoxProps {
    searchValue?: string;
    onSearchValueChange: (newValue: string) => void;
}
export function ProjectsDashboardSearchBox({ searchValue, onSearchValueChange }: ProjectDashboardSearchBoxProps) {
    const [internalSearchValue, setInternalSearchValue] = React.useState(searchValue ?? "");
    const debouncedOnSearchValueChange = useDebouncedCallback(() => onSearchValueChange(internalSearchValue), 200);
    const internalOnSearchValueChange = (newValue: string) => {
        setInternalSearchValue(newValue);
        if (newValue === "") {
            debouncedOnSearchValueChange.cancel();
            onSearchValueChange("");
        }
        else {
            debouncedOnSearchValueChange();
        }
    };
    return (<div className={searchInputWrapperStyles}>
            <SearchInput value={internalSearchValue} onChange={internalOnSearchValueChange} accessibleName={"search project or project group"} placeholder={"Find projects or project groups"}/>
        </div>);
}
export function ProjectsDashboardSearchBoxSkeleton() {
    return (<div className={cx(searchInputWrapperStyles, searchInputSkeletonStyles)}>
            <Skeleton shape={"Rounded"} borderRadius={"small"}/>
        </div>);
}
const searchInputWrapperStyles = css({
    width: "21rem",
});
const searchInputSkeletonStyles = css({
    height: "2.25rem",
});
