import { HostingEnvironment, MachineModelHealthStatus } from "@octopusdeploy/octopus-server-client";
import type { LinkHref, Url } from "@octopusdeploy/portal-routes";
import { cloneDeep } from "lodash";
import moment from "moment/moment";
import * as React from "react";
import URI from "urijs";
import type { AnalyticGuidedSetupDispatched } from "~/analytics/Analytics";
import type { EndpointCardDialogProps } from "~/areas/infrastructure/components/MachineSettings/Endpoints/EndpointCard";
import { generateBearerToken } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/BearerTokenGenerator";
import { ConfigurationMessages } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationMessages";
import type { BaseConfiguration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationPage";
import { HaNodeConfigurationPage } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/HaNodeConfigurationPage";
import { NfsDriverInstallationPage } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/NfsDriverInstallationPage";
import { EndpointSelectionScope } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { client, repository } from "~/clientInstance";
import type { FormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import { FormBaseComponent } from "~/components/FormBaseComponent/FormBaseComponent";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import type { SpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/SpaceAwareNavigation";
import type { HelpPanelImage } from "~/components/OnboardingDialog/HelpPanel";
import type { PagedOnboardingDialogPageData } from "~/components/OnboardingDialog/PagedOnboardingDialog";
import { PagedOnboardingDialog } from "~/components/OnboardingDialog/PagedOnboardingDialog";
import StringHelper from "~/utils/StringHelper/index";
import type { TargetConfigurationResource } from "./KubernetesAgentTargetConfigurationDialog";
interface ProjectSetupProps {
    projectId?: string;
    correlationId?: string;
    sourcePage?: string;
    navigate?: SpaceAwareNavigation;
    dispatchAction?: AnalyticGuidedSetupDispatched;
}
export type ConfigurationProps = EndpointCardDialogProps & ProjectSetupProps & {
    onTargetCompleted?: <K extends keyof TargetConfigurationResource>(model: Pick<TargetConfigurationResource, K>) => void;
};
export type FieldErrors = {
    [key: string]: string;
};
export type NameConfigurationMessages = {
    HelperText: string;
    Validation: {
        NotProvided: string;
        AlreadyExists: string;
    };
};
export interface ServerNodeCommsAddress {
    nodeName: string;
    commsAddress: string;
}
export interface ConfigurationState<Configuration extends BaseConfiguration> extends FormBaseComponentState<Configuration> {
    canModifyServerUrls: boolean;
    showAdvanced: boolean;
    isBusy: boolean;
    redirectTo?: LinkHref;
    hasTokenExpired: boolean;
    accessTokenExpiryCheckIntervalId: ReturnType<typeof setTimeout> | undefined;
    accessTokenExpiry: moment.Moment | undefined;
    usePersistentVolumeStorage: boolean;
    requiresHaConfiguration: boolean;
}
export abstract class ConfigurationDialog<State extends ConfigurationState<ModelState>, ModelState extends BaseConfiguration, Props extends ConfigurationProps = ConfigurationProps> extends FormBaseComponent<Props, State, ModelState> {
    protected constructor(props: Props, machineType: EndpointSelectionScope, nameErrorMessages: NameConfigurationMessages) {
        super(props);
        this.machineType = machineType;
        this.nameErrorMessages = nameErrorMessages;
        this.initialiseState();
    }
    nameErrorMessages: NameConfigurationMessages;
    machineType: EndpointSelectionScope;
    defaultCommsPort = "10943"; // see https://octopus.com/docs/infrastructure/deployment-targets/tentacle/tentacle-communication#polling-tentacles
    cloudHostedCommsPort = ""; // see https://octopus.com/docs/infrastructure/deployment-targets/tentacle/polling-tentacles-over-port-443#octopus-cloud
    cloudHostedSubdomain = "polling";
    protected getInitialBaseModel(): BaseConfiguration {
        return {
            Space: undefined,
            Name: "",
            ServerUri: client.resolve("/"),
            ServerNodeCommsAddresses: [],
            AccessToken: "",
            StorageClassName: "",
        };
    }
    protected initialBaseState: ConfigurationState<ModelState> = {
        canModifyServerUrls: false,
        showAdvanced: false,
        model: this.initialModel(),
        cleanModel: this.initialModel(),
        isBusy: false,
        redirectTo: undefined,
        hasTokenExpired: false,
        accessTokenExpiryCheckIntervalId: undefined,
        accessTokenExpiry: undefined,
        usePersistentVolumeStorage: false,
        requiresHaConfiguration: false,
    };
    abstract initialModel(): ModelState;
    abstract initialiseState(): void;
    abstract loadMachineTypeSpecificData(): Promise<void>;
    abstract machineIsFound(): boolean;
    abstract machineHealthStatus(): MachineModelHealthStatus | undefined;
    abstract getLinkToMachine(): Url | undefined;
    abstract getPages(): PagedOnboardingDialogPageData[];
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const licencePromise = repository.Licenses.getCurrentStatus();
            if (!repository.spaceId) {
                throw new Error("Attempted to render Kubernetes Agent Configuration Dialog in a system context. This should never happen.");
            }
            const spacePromise = repository.Spaces.get(repository.spaceId);
            const serverNodes = await repository.OctopusServerNodes.all();
            const isSelfHosted = (await licencePromise).HostingEnvironment === HostingEnvironment.SelfHosted;
            const uri = new URI(this.state.model.ServerUri);
            const requiresHaConfiguration = isSelfHosted && serverNodes.length > 1;
            const serverNodeCommsAddresses: ServerNodeCommsAddress[] = [];
            if (requiresHaConfiguration) {
                for (const node of serverNodes) {
                    serverNodeCommsAddresses.push({ nodeName: node.Name, commsAddress: "" });
                }
            }
            else {
                const serverCommsAddress = isSelfHosted ? uri.port(this.defaultCommsPort).toString() : uri.subdomain(`${this.cloudHostedSubdomain}.${uri.subdomain()}`).port(this.cloudHostedCommsPort).toString();
                serverNodeCommsAddresses.push({ nodeName: "", commsAddress: serverCommsAddress });
            }
            this.setBaseConfiguration({
                Space: await spacePromise,
                ServerNodeCommsAddresses: serverNodeCommsAddresses,
            });
            this.setState({
                canModifyServerUrls: isSelfHosted,
                cleanModel: cloneDeep(this.state.model),
                requiresHaConfiguration,
            });
            await this.loadMachineTypeSpecificData();
        }, {});
        const pollingIntervalMs = 5000;
        const accessTokenExpiryCheckIntervalId: ReturnType<typeof setTimeout> = setInterval(async () => {
            this.setState({ hasTokenExpired: this.state.accessTokenExpiry != undefined && this.state.accessTokenExpiry < moment() });
        }, pollingIntervalMs);
        this.setState({ accessTokenExpiryCheckIntervalId });
    }
    setBaseConfiguration<K extends keyof BaseConfiguration>(configuration: Pick<BaseConfiguration, K>) {
        this.setModelState({ ...this.state.model, ...configuration });
    }
    componentWillUnmount() {
        clearInterval(this.state.accessTokenExpiryCheckIntervalId);
        super.componentWillUnmount();
    }
    isValidUrl = (url: string): boolean => {
        try {
            const uri = new URI(url);
            return uri.is("absolute") && uri.is("url") && (uri.is("name") || uri.is("ip"));
        }
        catch (error) {
            return false;
        }
    };
    isHaConfigValid = async () => {
        this.clearErrors();
        const previousCommsAddresses: string[] = [];
        const fieldErrors: {
            [p: string]: string;
        } = {};
        for (const { nodeName, commsAddress } of this.state.model.ServerNodeCommsAddresses) {
            if (StringHelper.isNullOrWhiteSpace(commsAddress)) {
                fieldErrors[nodeName] = ConfigurationMessages.ServerCommsAddresses.Validation.NotProvided;
            }
            else if (!this.isValidUrl(commsAddress)) {
                fieldErrors[nodeName] = ConfigurationMessages.ServerCommsAddresses.Validation.Invalid;
            }
            else if (previousCommsAddresses.includes(commsAddress)) {
                fieldErrors[nodeName] = ConfigurationMessages.ServerCommsAddresses.Validation.NotUnique;
            }
            previousCommsAddresses.push(commsAddress);
        }
        if (Object.keys(fieldErrors).length > 0) {
            this.setValidationErrors("The following fields have invalid values", fieldErrors);
            return false;
        }
        return true;
    };
    abstract validateMachineTypeSpecificData(model: ModelState, fieldErrors: FieldErrors): Promise<void>;
    isModelValid = async () => {
        await this.doBusyTask(async () => {
            this.setState({ isBusy: true });
            this.clearErrors();
            const fieldErrors: FieldErrors = {};
            const model = this.state.model;
            if (StringHelper.isNullOrWhiteSpace(model.ServerUri) || !this.isValidUrl(model.ServerUri)) {
                fieldErrors.ServerUri = ConfigurationMessages.ServerUri.Validation.Invalid;
                this.setState({ showAdvanced: true });
            }
            if (model.ServerNodeCommsAddresses.length === 1) {
                if (StringHelper.isNullOrWhiteSpace(model.ServerNodeCommsAddresses[0].commsAddress) || !this.isValidUrl(model.ServerNodeCommsAddresses[0].commsAddress)) {
                    fieldErrors.ServerNodeCommsAddresses = ConfigurationMessages.ServerCommsAddresses.Validation.Invalid;
                    this.setState({ showAdvanced: true });
                }
            }
            await this.validateMachineTypeSpecificData(model, fieldErrors);
            if (Object.keys(fieldErrors).length > 0) {
                this.setValidationErrors("The following fields have invalid values", fieldErrors);
            }
        });
        this.setState({ isBusy: false });
        if (this.errors?.fieldErrors) {
            return Object.keys(this.errors.fieldErrors).length === 0;
        }
        return true;
    };
    refreshToken = async () => {
        await this.doBusyTask(async () => {
            this.setState({ isBusy: true });
            this.clearErrors();
            const { token, expiry } = await generateBearerToken();
            this.setModelState({ AccessToken: token.AccessToken });
            this.setState({ accessTokenExpiry: expiry, hasTokenExpired: expiry < moment() });
        });
        this.setState({ isBusy: false });
    };
    clearModel = () => this.setModelState(this.state.cleanModel);
    resetState = () => this.setState({
        showAdvanced: false,
        usePersistentVolumeStorage: false,
    });
    getNfsCsiDriverInstallationPage(helpPanelContent: JSX.Element, helpPanelImage: HelpPanelImage): PagedOnboardingDialogPageData {
        return {
            name: "Install NFS CSI Driver",
            title: "Install NFS CSI Driver",
            helpPanelContent,
            helpPanelImage,
            alwaysShowCancelButton: true,
            onMovingToNextPage: async () => {
                this.props.dispatchAction?.("Submit NFS CSI Screen", { projectId: this.props.projectId, correlationId: this.props.correlationId, sourcePage: this.props.sourcePage });
            },
            render: () => <NfsDriverInstallationPage />,
        };
    }
    getHaConfigurationPage(helpPanelContent: JSX.Element, helpPanelImage: HelpPanelImage): PagedOnboardingDialogPageData {
        return {
            name: "Configure High Availability Nodes",
            title: "Configure High Availability Nodes",
            helpPanelContent,
            helpPanelImage,
            isPageModelValid: () => this.isHaConfigValid(),
            onMovingToNextPage: () => this.refreshToken(),
            isBusy: this.state.isBusy,
            alwaysShowCancelButton: true,
            render: () => (<HaNodeConfigurationPage serverCommsAddresses={this.state.model.ServerNodeCommsAddresses} onServerCommsAddressesChange={(ServerNodeCommsAddresses) => this.setModelState({ ServerNodeCommsAddresses })} getFieldError={(name: string) => this.getFieldError(name)}/>),
        };
    }
    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={false}/>;
        }
        const pages = this.getPages();
        const completeButtonText = this.machineIsFound() ? `View ${this.machineType === EndpointSelectionScope.Worker ? "Worker" : "Deployment Target"}` : undefined;
        return (<PagedOnboardingDialog open={this.props.open} close={(isCancel) => {
                this.clearModel();
                this.resetState();
                this.clearErrors();
                this.props.closeDialog();
                const redirectTo = this.getLinkToMachine();
                if (redirectTo && !isCancel) {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    this.setState({ redirectTo });
                    // This workaround is used for cases when the component is hidden behind an 'if' condition
                    // and can't re-render on state update.
                    this.props.navigate?.navigate(redirectTo);
                }
            }} name={"Add Kubernetes Agent"} showPageIndicator={true} completeButtonText={completeButtonText} hidePreviousButton={this.props.projectId ? this.machineHealthStatus() === MachineModelHealthStatus.Healthy : this.machineIsFound()} hideCompleteButton={this.props.projectId ? this.machineHealthStatus() !== MachineModelHealthStatus.Unhealthy : !this.machineIsFound()} contentPanelHeight={600} pages={pages}/>);
    }
    static displayName = "ConfigurationDialog";
}
