import { css, cx } from "@emotion/css";
import { Pagination, useIsMobile } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import { Permission, type DetailedProjectsDashboardResource, type InitialProjectsDashboardResource, type ProjectsDashboardFilter, type ProjectsPageResource, type UserFavouriteProjectSummary } from "@octopusdeploy/octopus-server-client";
import { useState, useRef, useCallback } from "react";
import * as React from "react";
import { DashboardProjectGroups, ProjectGroupDeploymentsSkeleton } from "~/areas/dashboard/DashboardOverview/DashboardProjectGroups";
import type { FavouriteProjectsData } from "~/areas/projects/components/DashboardDataSource/DataSet";
import { repository, session } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import { useRefreshLoop } from "~/hooks/useRefreshLoop";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import RequestRaceConditioner from "~/utils/RequestRaceConditioner/index";
import { ProjectsDashboardAdvancedFilters, ProjectsDashboardAdvancedFiltersSkeleton } from "./ProjectsDashboardAdvancedFilters";
import { ProjectsDashboardSearchBox, ProjectsDashboardSearchBoxSkeleton } from "./ProjectsDashboardSearchBox";
import { Skeleton } from "./Skeleton";
export interface ProjectsFilter {
    page: number;
    searchValue: string;
}
export interface ProjectsDashboardProps {
    spaceId: string;
    doBusyTask: DoBusyTask;
    filter: ProjectsFilter;
    setFilter: (newFilter: ProjectsFilter) => void;
}
const ITEM_PER_PAGE = 30;
export function ProjectsDashboard({ doBusyTask, spaceId, filter, setFilter }: ProjectsDashboardProps) {
    const isMobile = useIsMobile();
    const [advancedFilterChanged, setAdvancedFilterChanged] = useState(false);
    const initialDashboardData = useInitialDashboardData(doBusyTask, getDashboardFiltersQueryParams(filter, ITEM_PER_PAGE), advancedFilterChanged);
    const detailedDashboardData = useDetailedDashboardData(doBusyTask, getDashboardFiltersQueryParams(filter, ITEM_PER_PAGE), advancedFilterChanged);
    const favouriteProjectsData = useFavouriteData(doBusyTask);
    const dashboardData = detailedDashboardData ?? initialDashboardData;
    const onSearchValueChanged = (newValue: string) => {
        setFilter({ searchValue: newValue, page: 1 });
    };
    const onPageChange = (page: number) => {
        setFilter({ ...filter, page });
    };
    const onAdvancedFiltersChanged = () => {
        setFilter({ ...filter, page: 1 });
        setAdvancedFilterChanged((value) => !value);
    };
    if (dashboardData === undefined) {
        return <InitialLoadingSkeleton />;
    }
    const { TotalResults, ItemsPerPage, ProjectGroups } = isLoading(dashboardData) ? dashboardData.staleData : dashboardData;
    const pagination = <Pagination label="Projects displayed" totalResults={TotalResults} itemsPerPage={ItemsPerPage} currentPage={filter.page} onPageChange={onPageChange}/>;
    return (<div className={cx(projectsDashboardStyles, { [projectsDashboardMobileStyles]: isMobile })}>
            <div className={filterAndPaginationContainerStyles}>
                <div className={cx(searchBoxAndAdvancedFiltersWrapperStyles, { [filterAndPaginationMobileStyles]: isMobile })}>
                    <ProjectsDashboardSearchBox searchValue={filter.searchValue} onSearchValueChange={onSearchValueChanged}/>
                    <ProjectsDashboardAdvancedFilters doBusyTask={doBusyTask} onAdvancedFilterChange={onAdvancedFiltersChanged}/>
                </div>
                {pagination}
            </div>
            {isLoading(dashboardData) ? <ProjectGroupDeploymentsSkeleton /> : <DashboardProjectGroups projectGroups={ProjectGroups} favouriteProjectsData={favouriteProjectsData} spaceId={spaceId}/>}
            {!isLoading(dashboardData) && <div className={bottomPaginationContainerStyles}>{pagination}</div>}
        </div>);
}
function InitialLoadingSkeleton() {
    const isMobile = useIsMobile();
    return (<div className={cx(projectsDashboardStyles, { [projectsDashboardMobileStyles]: isMobile })}>
            <div className={filterAndPaginationContainerStyles}>
                <div className={cx(searchBoxAndAdvancedFiltersWrapperStyles, { [filterAndPaginationMobileStyles]: isMobile })}>
                    <ProjectsDashboardSearchBoxSkeleton />
                    <ProjectsDashboardAdvancedFiltersSkeleton />
                </div>
                <PaginationSkeleton />
            </div>
            <ProjectGroupDeploymentsSkeleton />
        </div>);
}
function PaginationSkeleton() {
    return (<div className={paginationSkeletonStyles}>
            <Skeleton shape={"Rounded"} borderRadius={"medium"}/>
        </div>);
}
const paginationSkeletonStyles = css({
    height: "100%",
    width: "17.25rem",
});
const projectsDashboardStyles = css({
    display: "flex",
    flexDirection: "column",
    gap: space[24],
    padding: `0 ${space["32"]} ${space["32"]}`,
});
const projectsDashboardMobileStyles = css({
    padding: `0 ${space["16"]} ${space["32"]}`,
});
const filterAndPaginationContainerStyles = css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${space["8"]} 0`,
    "@media (max-width: 1024px)": {
        flexDirection: "column",
        alignItems: "flex-start",
        gap: space["4"],
    },
});
const filterAndPaginationMobileStyles = css({
    flexDirection: "column",
    alignItems: "flex-start",
    gap: space["4"],
});
const searchBoxAndAdvancedFiltersWrapperStyles = css({
    display: "flex",
    alignItems: "center",
    flex: 1,
    gap: space["16"],
});
const bottomPaginationContainerStyles = css({
    alignSelf: "flex-end",
});
function useFavouriteData(doBusyTask: DoBusyTask): FavouriteProjectsData {
    const [favouriteProjects, setFavourites] = useState<UserFavouriteProjectSummary[]>([]);
    useDoBusyTaskEffect(doBusyTask, async () => {
        if (hasPermission(Permission.ProjectView)) {
            const result = await repository.Users.getProjectFavourites();
            setFavourites(result.Projects);
        }
        else {
            setFavourites([]);
        }
    }, []);
    const onFavouriteProjectChanged = useCallback((projectId: string, isFavourite: boolean) => doBusyTask(async () => {
        if (isFavourite) {
            const { Project } = await repository.Users.createProjectFavourite(projectId);
            setFavourites((prev) => [...prev, Project]);
        }
        else {
            await repository.Users.deleteProjectFavourite(projectId);
            setFavourites((prev) => prev.filter((p) => p.Id !== projectId));
        }
    }), [doBusyTask]);
    return { favouriteProjects, onFavouriteProjectChanged };
}
function useInitialDashboardData(doBusyTask: DoBusyTask, dashboardFilters: ProjectsDashboardFilter, advancedFilterChanged: boolean) {
    const [initialDashboardData, setInitialDashboardData] = useState<InitialProjectsDashboardResource | undefined>(undefined);
    const raceConditioner = useRef(new RequestRaceConditioner());
    useDoBusyTaskEffect(doBusyTask, async () => {
        await raceConditioner.current.avoidStaleResponsesForRequest(repository.Dashboards.getInitialProjectsDashboard(dashboardFilters), (result) => setInitialDashboardData(result));
    }, [dashboardFilters.skip, dashboardFilters.take, dashboardFilters.projectOrGroupPartialName, advancedFilterChanged]);
    return initialDashboardData;
}
export interface IsLoading<T> {
    staleData: T;
}
export function isLoading<T>(item: T | IsLoading<T>): item is IsLoading<T> {
    if (item === undefined || item === null || typeof item !== "object") {
        return false;
    }
    return "staleData" in item;
}
type ProjectsDashboardData = DetailedProjectsDashboardResource | ProjectsPageResource;
function useDetailedDashboardData(doBusyTask: DoBusyTask, dashboardFilters: ProjectsDashboardFilter, advancedFilterChanged: boolean) {
    const [detailedDashboardData, setDetailedDashboardData] = useState<ProjectsDashboardData | IsLoading<ProjectsDashboardData> | undefined>(undefined);
    const raceConditioner = useRef(new RequestRaceConditioner());
    const { skip, take, projectOrGroupPartialName } = dashboardFilters;
    const loadData = React.useCallback(async (indicateLoading: boolean) => {
        if (indicateLoading) {
            setDetailedDashboardData((prev) => {
                if (!prev || "staleData" in prev) {
                    return prev;
                }
                return { staleData: prev };
            });
        }
        if (session.features?.IsProjectsPageOptimizationEnabled ?? false) {
            await raceConditioner.current.avoidStaleResponsesForRequest(repository.Dashboards.getProjectsPage({ skip, take, projectOrGroupPartialName }), (result) => {
                setDetailedDashboardData(result);
            });
        }
        else {
            await raceConditioner.current.avoidStaleResponsesForRequest(repository.Dashboards.getDetailedProjectsDashboard({ skip, take, projectOrGroupPartialName }), (result) => {
                setDetailedDashboardData(result);
            });
        }
    }, [skip, take, projectOrGroupPartialName]);
    useDoBusyTaskEffect(doBusyTask, async () => {
        await loadData(true);
    }, [loadData, advancedFilterChanged], { timeOperationOptions: timeOperationOptions.forRefresh() });
    useRefreshLoop(() => loadData(false), dashboardRefreshIntervalInMs);
    return detailedDashboardData;
}
const dashboardRefreshIntervalInMs = 6000;
function getDashboardFiltersQueryParams(filters: ProjectsFilter, itemPerPage: number) {
    const skip = (filters.page - 1) * itemPerPage;
    const take = itemPerPage;
    const projectOrGroupPartialName = filters.searchValue?.toLowerCase() ?? "";
    return { skip, take, projectOrGroupPartialName };
}
