import type { ShowSnackbar } from "@octopusdeploy/design-system-components";
import { useShowSnackbar } from "@octopusdeploy/design-system-components";
import { AnalyticLinkLocationProvider } from "@octopusdeploy/portal-analytics";
import { BaseComponent } from "app/components/BaseComponent/BaseComponent";
import type * as History from "history";
import { isEqual } from "lodash";
import * as React from "react";
import { Prompt, useLocation } from "react-router";
import type { FieldErrors } from "~/components/DataBaseComponent/Errors";
import { DevToolsTab } from "~/components/DevTools/DevToolsContext";
import { useErrors } from "~/components/ErrorContext/ErrorContext";
import FormComponent from "~/components/FormComponent/FormComponent";
import { DirtyStateDetail } from "~/components/FormPaperLayout/DirtyStateTracking/DirtyStateDetail";
import { createFormDirtyChangedAction, createFormMountedAction } from "~/components/FormPaperLayout/reducers";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import ExpansionButtons, { toggleExpandos } from "~/components/form/Sections/ExpansionButtons";
import store from "~/store";
import { timeOperation, timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
export interface FormProps {
    model: object | undefined;
    cleanModel?: object;
    saveText?: string;
    savePermission?: PermissionCheckProps | undefined;
    disableDirtyFormChecking?: boolean;
    disableKeyboardFormSubmission?: boolean;
    forceDisableFormSaveButton?: boolean;
    devToolsDirtyTrackingKey?: string;
    devToolsDirtyTrackingDisabled?: boolean;
    onSaveClick(isNavigationConfirmation: boolean): Promise<{} | void> | void;
    confirmNavigateSaveLabel?: string;
    children: (props: RenderFormProps) => React.ReactNode;
}
export interface PrimarySaveActionOptions {
    saveButtonLabel?: string;
    saveButtonBusyLabel?: string;
}
type PrimarySaveActionOptionsWithDefaults = Required<PrimarySaveActionOptions>;
interface RenderFormProps {
    createSaveAction: (options: PrimarySaveActionOptions) => PrimarySavePageAction;
    FormContent: React.ComponentType<FormContentProps>;
    isDisabled: boolean;
    save: (isNavigationConfirmation: boolean) => Promise<boolean>;
}
export function Form({ children, devToolsDirtyTrackingKey, devToolsDirtyTrackingDisabled, disableDirtyFormChecking, disableKeyboardFormSubmission, forceDisableFormSaveButton, saveText, confirmNavigateSaveLabel, savePermission, onSaveClick, model, cleanModel, }: FormProps) {
    const errors = useErrors();
    const showSnackbar = useShowSnackbar();
    // It would be good to useMemo around this check because it could be expensive for larger models.
    // However, at the time of writing this code, some components mutate the model rather than returning a new instance
    // See https://github.com/OctopusDeploy/OctopusDeploy/pull/26880 which was caused by
    // a mutation in DynamicForm: https://github.com/OctopusDeploy/OctopusDeploy/blob/a2b6efc799ad4b29f724f1325ced152f22e40730/frontend/apps/portal/app/components/DynamicForm/DynamicForm.tsx#L505-L507
    const dirty = disableDirtyFormChecking ? "disabled" : !isEqual(model, cleanModel);
    return (<FormInternal errors={errors?.fieldErrors} showSnackbar={showSnackbar} dirty={dirty} devToolsDirtyTrackingKey={devToolsDirtyTrackingKey} devToolsDirtyTrackingDisabled={devToolsDirtyTrackingDisabled} disableKeyboardFormSubmission={disableKeyboardFormSubmission} forceDisableFormSaveButton={forceDisableFormSaveButton} saveText={saveText} confirmNavigateSaveLabel={confirmNavigateSaveLabel} savePermission={savePermission} onSaveClick={onSaveClick} model={model} cleanModel={cleanModel}>
            {children}
        </FormInternal>);
}
interface FormPropsInternal {
    errors: FieldErrors | undefined;
    showSnackbar: ShowSnackbar;
    dirty: boolean | "disabled";
    model: object | undefined;
    cleanModel: object | undefined;
    saveText: string | undefined;
    savePermission: PermissionCheckProps | undefined;
    disableKeyboardFormSubmission: boolean | undefined;
    forceDisableFormSaveButton: boolean | undefined;
    devToolsDirtyTrackingKey: string | undefined;
    devToolsDirtyTrackingDisabled: boolean | undefined;
    onSaveClick(isNavigationConfirmation: boolean): Promise<{} | void> | void;
    confirmNavigateSaveLabel: string | undefined;
    children: (props: RenderFormProps) => React.ReactNode;
}
class FormInternal extends BaseComponent<FormPropsInternal> {
    public static defaultProps: Partial<FormPropsInternal> = {
        saveText: "Details updated",
        devToolsDirtyTrackingKey: "Form",
    };
    UNSAFE_componentWillReceiveProps(nextProps: FormPropsInternal) {
        if (nextProps.dirty !== this.props.dirty && nextProps.dirty !== "disabled") {
            store.dispatch(createFormDirtyChangedAction(nextProps.dirty));
        }
    }
    componentDidMount() {
        store.dispatch(createFormMountedAction(this.saveOnConfirmNavigation, this.props.confirmNavigateSaveLabel));
        store.dispatch(createFormDirtyChangedAction(this.props.dirty === "disabled" ? false : this.props.dirty));
    }
    componentWillUnmount() {
        store.dispatch(createFormMountedAction(undefined, undefined));
        store.dispatch(createFormDirtyChangedAction(false));
    }
    createSaveAction = ({ saveButtonLabel = "Save", saveButtonBusyLabel = "Saving" }: PrimarySaveActionOptions): PrimarySavePageAction => {
        const optionsWithDefaults = { saveButtonLabel, saveButtonBusyLabel } satisfies PrimarySaveActionOptionsWithDefaults;
        return {
            type: "button" as const,
            label: this.getSaveActionLabel(optionsWithDefaults),
            busyLabel: optionsWithDefaults.saveButtonBusyLabel,
            disabled: this.shouldBeDisabled(this.props.savePermission),
            onClick: (e: React.MouseEvent | undefined) => {
                if (e) {
                    e.preventDefault();
                }
                return this.save(false);
            },
        };
    };
    getSaveActionLabel({ saveButtonLabel }: PrimarySaveActionOptionsWithDefaults): string {
        const disabledDueToPermission = this.isDisableDueToPermission(this.props.savePermission);
        const permissionLabel = this.getPermissionLabel(this.props.savePermission);
        return disabledDueToPermission ? `${permissionLabel} permission required` : saveButtonLabel;
    }
    private isDisableDueToPermission = (permission: PermissionCheckProps | undefined) => {
        return !!permission ? !isAllowed(permission) : false;
    };
    private getPermissionLabel(permission: PermissionCheckProps | undefined): string {
        if (permission === undefined) {
            return "No";
        }
        if (Array.isArray(permission.permission)) {
            return permission.permission.join(", ");
        }
        return permission.permission;
    }
    private shouldBeDisabled = (permission: PermissionCheckProps | undefined): boolean => {
        const isDisabledDueToPermission = this.isDisableDueToPermission(permission);
        const isDisabledFromBeingDirty = this.props.dirty === "disabled" ? false : !this.props.dirty || !this.props.model;
        return isDisabledDueToPermission || isDisabledFromBeingDirty || Boolean(this.props.forceDisableFormSaveButton);
    };
    private onCtrlEnterPressed = async () => {
        if (!this.shouldBeDisabled(this.props.savePermission) && !this.props.disableKeyboardFormSubmission) {
            await this.save(false);
        }
    };
    private saveOnConfirmNavigation = async () => {
        await this.save(true);
    };
    private save = (isNavigationConfirmation: boolean): Promise<boolean> => timeOperation(timeOperationOptions.forSave(), async () => {
        await this.props.onSaveClick(isNavigationConfirmation);
        if (!this.props.errors) {
            if (this.props.saveText) {
                this.props.showSnackbar(this.props.saveText);
            }
        }
        if (!this.props.errors) {
            toggleExpandos(false);
        }
        return !this.props.errors;
    });
    render() {
        return (<FormComponent onFormSubmit={this.onCtrlEnterPressed}>
                {!this.props.devToolsDirtyTrackingDisabled && (<DevToolsTab name={`Dirty state: ${this.props.devToolsDirtyTrackingKey ?? "Form"}`}>
                        <DirtyStateDetail cleanModel={this.props.cleanModel} model={this.props.model}/>
                    </DevToolsTab>)}
                <NavigateAwayPrompt when={this.props.dirty === "disabled" ? false : this.props.dirty}/>
                {this.props.children({ createSaveAction: this.createSaveAction, save: this.save, FormContent, isDisabled: Boolean(this.shouldBeDisabled(this.props.savePermission)) })}
            </FormComponent>);
    }
    static displayName = "FormInternal";
}
export interface PrimarySavePageAction {
    type: "button";
    label: string;
    disabled: boolean;
    busyLabel: string;
    onClick: (event: React.MouseEvent | undefined) => Promise<boolean>;
}
interface FormContentProps {
    hideExpandAll?: boolean;
    expandAllOnMount?: boolean;
    children: React.ReactNode;
    containerKey?: string;
}
function FormContent({ expandAllOnMount, hideExpandAll, children, containerKey }: FormContentProps) {
    const errors = useErrors();
    return (<>
            {!hideExpandAll && <ExpansionButtons errors={errors?.fieldErrors} expandAllOnMount={expandAllOnMount} containerKey={containerKey}/>}
            <AnalyticLinkLocationProvider location="Paper Form">{children}</AnalyticLinkLocationProvider>
        </>);
}
interface NavigateAwayPromptProps {
    when: boolean;
}
function NavigateAwayPrompt({ when }: NavigateAwayPromptProps) {
    const currentLocation = useLocation();
    // If the pathname hasn't changed, return true which will allow the transition.
    // This is so we can ignore filter changes which only modify the query string.
    const getPromptMessage = React.useCallback((nextLocation: History.Location) => {
        if (nextLocation.pathname === currentLocation.pathname) {
            return true;
        }
        return "If you leave this page, any changes you have made will be lost. Are you sure you wish to leave this page?";
    }, [currentLocation.pathname]);
    return <Prompt when={when} message={getPromptMessage}/>;
}
