/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { borderRadius, fontSize, fontWeight, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import { ActionHandlerCategory, CommunicationStyle, type DeploymentTargetResource, type EnvironmentResource, type ProjectResource } from "@octopusdeploy/octopus-server-client";
import type { Url } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import React, { useState } from "react";
import { useAnalyticTrackedActionDispatch, useAnalyticActionDispatch, useAnalyticGuidedSetupDispatch, useCorrelationId } from "~/analytics/Analytics";
import { ProjectStatus } from "~/areas/projects/components/ProjectStatus/ProjectStatus";
import { ProjectInfrastructure, type ProjectCreatedOption } from "~/areas/projects/components/Projects/AddProject";
import { ProjectCardIcon } from "~/areas/projects/components/Projects/ProjectCardIcon";
import { repository } from "~/clientInstance";
import useLocalStorage from "~/hooks/useLocalStorage";
import { useDoBusyTaskEffect, type DoBusyTask } from "../DataBaseComponent";
import { useSpaceAwareNavigation } from "../Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { CreateEnvironmentsPage } from "./CreateEnvironmentsDialog";
import CreateNewKubernetesCluster from "./CreateNewKubernetesCluster";
import CreateNewProject from "./CreateNewProject";
interface NewProjectWizardProps {
    spaceId: string;
    projectSlug?: string;
    projectGroupId?: string;
    open: boolean;
    fullScreen?: boolean;
    saveProjectOptions?: boolean;
    close?: () => void;
    back?: () => void;
    busy: Promise<void> | undefined;
    doBusyTask: DoBusyTask;
}
interface NewProjectWizardState {
    project: ProjectResource;
    projectOption: ProjectCreatedOption;
    environments?: Array<EnvironmentResource>;
    targets?: Array<DeploymentTargetResource>;
}
type PageType = "Project" | "Environments" | "KubernetesCluster" | "ProjectDetailsPage";
const defaultProjectOptions: ProjectCreatedOption = {
    vcsRedirect: false,
    newlyCreatedProjectRedirect: true,
    infrastructure: undefined,
    step: undefined,
};
function NewProjectWizard(props: NewProjectWizardProps) {
    const [currentPage, setCurrentPage] = useState<PageType>("Project");
    const [initialPage, setInitialPage] = useState<PageType>("Project");
    const [state, setState] = useState<NewProjectWizardState>();
    const navigate = useSpaceAwareNavigation();
    const [initialProjectOption, setInitialProjectOption] = useLocalStorage<ProjectCreatedOption>(`onboardingDialog.${props.spaceId}.ProjectOptions`, defaultProjectOptions);
    const [initialTargets, setInitialTargets] = useState<Array<DeploymentTargetResource>>([]);
    const correlationId = useCorrelationId();
    const trackAction = useAnalyticTrackedActionDispatch();
    const dispatchAction = useAnalyticActionDispatch();
    const guidedSetupDispatchAction = useAnalyticGuidedSetupDispatch();
    useDoBusyTaskEffect(props.doBusyTask, async () => {
        const project = props.projectSlug ? await repository.Projects.get(props.projectSlug!) : undefined;
        const environments = await repository.Environments.all();
        const allTargets = await repository.Machines.list();
        const kubernetesTargets = allTargets.Items.filter((x: DeploymentTargetResource) => x.Endpoint.CommunicationStyle === CommunicationStyle.KubernetesTentacle || x.Endpoint.CommunicationStyle == CommunicationStyle.Kubernetes);
        setInitialTargets(kubernetesTargets);
        if (props.projectSlug && project) {
            setState({ project: project, projectOption: initialProjectOption, environments: environments });
            const initialPage = getNextPage(project, initialProjectOption, environments, kubernetesTargets);
            setInitialPage(initialPage);
        }
        else {
            setState((prev) => ({ ...prev!, environments }));
        }
        nextPage(project, initialProjectOption, environments, kubernetesTargets);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const nextPage = (project: ProjectResource | undefined, projectOptions: ProjectCreatedOption | undefined, environments: Array<EnvironmentResource> | undefined, targets: DeploymentTargetResource[] | undefined) => {
        const nextPage = getNextPage(project, projectOptions, environments, targets);
        setCurrentPage(nextPage);
    };
    const previousPage = () => {
        props.back?.();
    };
    const closeAndRedirect = (toProject: boolean = true) => {
        if (!toProject) {
            navigate.navigate(links.projectsPage.generateUrl({ spaceId: props.spaceId }));
            props.close?.();
        }
        else {
            redirectToProject();
        }
    };
    const projectCreated = (project: ProjectResource, option: ProjectCreatedOption) => {
        guidedSetupDispatchAction("Submit New Project Setup Screen", {
            projectId: project.Id,
            correlationId: correlationId,
            projectType: option.infrastructure,
            projectSubType: option.step,
            versionControl: option.vcsRedirect,
            sourcePage: "Project Setup",
        });
        setState((prev) => ({ ...prev!, project: project, projectOption: option }));
        if (props.saveProjectOptions) {
            setInitialProjectOption(option);
        }
        nextPage(project, option, state?.environments, state?.targets?.length ? state?.targets : initialTargets);
    };
    const environmentsCreated = async () => {
        const environments = await repository.Environments.all();
        guidedSetupDispatchAction("Submit Environments Setup Screen", { projectId: state?.project.Id, correlationId: correlationId, environments: environments.map((x) => x.Name).join(";"), sourcePage: "Project Setup" });
        setState((prev) => ({ ...prev!, environments }));
        nextPage(state?.project, state?.projectOption, environments, state?.targets?.length ? state?.targets : initialTargets);
    };
    const environmentsSkipped = async () => {
        guidedSetupDispatchAction("Exit Project Setup", { projectId: state?.project.Id, correlationId: correlationId, screen: "Environments", sourcePage: "Project Setup" });
        nextPage(state?.project, state?.projectOption, undefined, undefined);
    };
    const clusterConnected = () => {
        nextPage(state?.project, state?.projectOption, state?.environments, state?.targets);
    };
    const clusterSkipped = () => {
        guidedSetupDispatchAction("Exit Project Setup", { projectId: state?.project.Id, correlationId: correlationId, screen: "Kubernetes Cluster", sourcePage: "Project Setup" });
        nextPage(state?.project, state?.projectOption, state?.environments, undefined);
    };
    const redirectToProject = () => {
        const redirectUrl = getProjectURL();
        if (redirectUrl) {
            guidedSetupDispatchAction("Project Setup Completed", { projectId: state?.project.Id, correlationId: correlationId, sourcePage: "Project Setup" });
            navigate.navigate(redirectUrl!);
        }
        props.close?.();
    };
    const getProjectURL = (): Url | undefined => {
        if (!state) {
            return;
        }
        if (props.projectSlug && initialPage === "ProjectDetailsPage") {
            return links.deploymentProcessPage.generateUrl({ spaceId: state.project.SpaceId, projectSlug: state.project.Slug }, { newlyCreatedProject: true });
        }
        if (props.projectSlug && initialPage !== "ProjectDetailsPage") {
            return links.deploymentProcessStepsPage.generateUrl({ spaceId: state.project.SpaceId, projectSlug: state.project.Slug }, { stepTemplates: true, newlyCreatedProject: true, actionCategory: ActionHandlerCategory.Featured });
        }
        if (state.projectOption.vcsRedirect) {
            return links.projectVersionControlSettingsPage.generateUrl({ spaceId: state.project.SpaceId, projectSlug: state.project.Slug }, { newlyCreatedProject: true });
        }
        if (!state.projectOption.infrastructure) {
            return links.deploymentProcessPage.generateUrl({ spaceId: state.project.SpaceId, projectSlug: state.project.Slug }, { newlyCreatedProject: true });
        }
        if (state.projectOption.infrastructure && !state.projectOption.step) {
            const stepCategory = getStepCategory(state.projectOption.infrastructure);
            return links.deploymentProcessStepsPage.generateUrl({ spaceId: state.project.SpaceId, projectSlug: state.project.Slug }, { stepTemplates: true, newlyCreatedProject: true, actionCategory: stepCategory });
        }
        const targetTags = state?.targets?.flatMap((target) => target.Roles);
        return links.deploymentProcessStepsPage.generateUrl({ spaceId: state.project.SpaceId, projectSlug: state.project.Slug }, { new: true, actionType: state.projectOption.step, parentStepId: undefined, templateId: undefined, newlyCreatedProject: true, tags: targetTags });
    };
    const getStepCategory = (projectInfrastructure: ProjectInfrastructure) => {
        switch (projectInfrastructure) {
            case ProjectInfrastructure.AWS:
                return ActionHandlerCategory.Aws;
            case ProjectInfrastructure.Azure:
                return ActionHandlerCategory.Azure;
            case ProjectInfrastructure.Windows:
                return ActionHandlerCategory.WindowsServer;
            case ProjectInfrastructure.Kubernetes:
                return ActionHandlerCategory.Kubernetes;
            default:
                return ActionHandlerCategory.Featured;
        }
    };
    const getNextPage = (project: ProjectResource | undefined, projectOptions: ProjectCreatedOption | undefined, environments: Array<EnvironmentResource> | undefined, targets: DeploymentTargetResource[] | undefined): PageType => {
        if (!project) {
            return "Project";
        }
        else if (!environments?.length && currentPage !== "Environments") {
            return "Environments";
        }
        else if (environments?.length && projectOptions?.step && currentPage !== "KubernetesCluster" && !targets?.length) {
            return "KubernetesCluster";
        }
        else {
            return "ProjectDetailsPage";
        }
    };
    const projectName = !state?.project?.Name ? "" : state.project.Name;
    const projectSlug = state?.project?.Slug;
    const isKubernetesFlow = state?.projectOption?.infrastructure === ProjectInfrastructure.Kubernetes;
    const pages = new Map<PageType, JSX.Element>();
    if (!props.open)
        return null;
    if (currentPage === "ProjectDetailsPage")
        redirectToProject();
    pages.set("Project", <CreateNewProject next={projectCreated} doBusyTask={props.doBusyTask} back={previousPage} close={() => closeAndRedirect(false)} spaceId={props.spaceId} projectGroupId={props.projectGroupId}/>);
    pages.set("Environments", <CreateEnvironmentsPage isKubernetesFlow={isKubernetesFlow} spaceId={props.spaceId} fullScreen={props.fullScreen} title={<NewProjectWizardTitle doBusyTask={props.doBusyTask} projectName={projectName} spaceId={props.spaceId} projectSlug={projectSlug}/>} next={async () => await environmentsCreated()} skip={environmentsSkipped} close={closeAndRedirect} trackAction={trackAction} dispatchAction={dispatchAction}/>);
    pages.set("KubernetesCluster", <CreateNewKubernetesCluster projectId={state?.project?.Id} correlationId={correlationId} next={clusterConnected} close={closeAndRedirect} fullScreen={props.fullScreen} title={<NewProjectWizardTitle spaceId={props.spaceId} projectName={projectName} projectSlug={projectSlug} doBusyTask={props.doBusyTask} showK8sStatusItem/>} environments={state?.environments} busy={props.busy} skip={clusterSkipped} onTargetsChange={(targets) => setState((prev) => ({ ...prev!, targets }))}/>);
    if (!pages.has(currentPage))
        return null;
    return pages.get(currentPage)!;
}
interface NewProjectWizardTitleProps {
    projectName: string;
    doBusyTask: DoBusyTask;
    spaceId: string;
    projectSlug: string | undefined;
    showK8sStatusItem?: boolean;
}
function NewProjectWizardTitle(props: NewProjectWizardTitleProps) {
    return (<div>
            <div className={newProjectWizardTitleStyles.projectContainer}>
                <div className="projectCard">
                    <ProjectCardIcon />
                </div>
                {props.projectName}
            </div>
            <div>
                <ProjectStatus doBusyTask={props.doBusyTask} spaceId={props.spaceId} projectSlug={props.projectSlug} showK8sStatusItem={props.showK8sStatusItem} hideTitle hideCloseButton flexibleWidth/>
            </div>
        </div>);
}
const newProjectWizardTitleStyles = {
    titleContainer: css({
        display: "flex",
        alignItems: "center",
        marginBottom: space[48],
    }),
    titleIcon: css({
        height: "32px",
        width: "32px",
    }),
    title: css({
        color: themeTokens.color.text.primary,
        fontSize: fontSize["large"],
        fontWeight: fontWeight["400"],
    }),
    projectContainer: css({
        display: "flex",
        alignItems: "center",
        marginBottom: space[16],
        marginTop: space[8],
        textOverflow: "ellipsis",
        color: themeTokens.color.text.primary,
        fontSize: fontSize["base"],
        fontWeight: fontWeight["700"],
        lineHeight: "1.5rem",
        "& .projectCard": {
            width: "24px",
            height: "24px",
            borderRadius: borderRadius.small,
            backgroundColor: themeTokens.color.background.secondary.default,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: space["8"],
            "& svg": {
                width: "18px",
                height: "18px",
            },
            "& svg path": {
                fill: themeTokens.color.icon.primary,
            },
        },
    }),
};
export default NewProjectWizard;
