/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { EnvironmentResource, ProjectResource, ResourceCollection, RunbookResource, TriggerResource } from "@octopusdeploy/octopus-server-client";
import { HasRunbooksInGit, Permission, TriggerActionCategory, TriggerActionType } from "@octopusdeploy/octopus-server-client";
import _ from "lodash";
import * as React from "react";
import { useState } from "react";
import { useProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import { PermissionCheck } from "~/components/PermissionCheck";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { ScheduledTriggersBarInternal } from "./ScheduledTriggersBarInternal";
interface ScheduledTriggersBarProps {
    project: ProjectResource;
    doBusyTask: DoBusyTask;
    runbook?: RunbookResource;
}
export function ScheduledTriggersBar({ project, doBusyTask, runbook }: ScheduledTriggersBarProps) {
    const projectContext = useProjectContext();
    const gitRef = projectContext.state.gitRef?.CanonicalName;
    const [environments, setEnvironments] = useState<EnvironmentResource[]>([]);
    const [runbookTriggers, setRunbookRunTriggers] = useState<TriggerResource[]>([]);
    const [runbooksInProject, setRunbookInProject] = useState<RunbookResource[]>();
    const [hasRunbookProcess, setHasRunbookProcess] = useState(false);
    useDoBusyTaskEffect(doBusyTask, async () => {
        if (!isAllowed({
            permission: Permission.TriggerView,
            project: project.Id,
        })) {
            return;
        }
        const environmentsResponse = repository.Environments.all();
        const runbookTriggersResponse: Promise<ResourceCollection<TriggerResource>> = runbook
            ? repository.Projects.getTriggers(project, gitRef, 0, undefined, TriggerActionType.RunRunbook, TriggerActionCategory.Runbook, [runbook.Id])
            : repository.Projects.getTriggers(project, gitRef, 0, undefined, TriggerActionType.RunRunbook, TriggerActionCategory.Runbook);
        const runbooksInProjectResponse = !runbook && repository.Projects.getRunbooks(project);
        setEnvironments(await environmentsResponse);
        runbookTriggersResponse && setRunbookRunTriggers((await runbookTriggersResponse)?.Items);
        runbooksInProjectResponse && setRunbookInProject((await runbooksInProjectResponse).Items);
    }, []);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const hasRunbooksInGit = HasRunbooksInGit(project.PersistenceSettings);
        const runbookGitRef = hasRunbooksInGit ? gitRef : undefined;
        if (runbook) {
            const runbookProcess = await repository.Runbooks.getRunbookProcess(project, runbook.RunbookProcessId, runbookGitRef);
            runbookProcess && runbookProcess.Steps.length > 0 && setHasRunbookProcess(true);
        }
        if (runbooksInProject) {
            const allRunbookProcessesInProject = await Promise.all(runbooksInProject.map((runbook) => repository.Runbooks.getRunbookProcess(project, runbook.RunbookProcessId, runbookGitRef)));
            _.some(allRunbookProcessesInProject, (runbookProcess) => runbookProcess.Steps.length > 0) && setHasRunbookProcess(true);
        }
    }, [runbook, runbooksInProject]);
    return (<PermissionCheck permission={Permission.TriggerView} project={project.Id}>
            <ScheduledTriggersBarInternal runbookTriggers={runbookTriggers} environments={environments} projectSlug={projectContext.state.model.Slug} spaceId={projectContext.state.model.SpaceId} hasRunbookProcess={hasRunbookProcess} runbookId={runbook?.Id} runbooksInProject={runbooksInProject}/>
        </PermissionCheck>);
}
