import { Environment } from "@octopusdeploy/utilities";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import { useUIOnlyFeatureFlag } from "~/hooks/useUIOnlyFeatureFlag";
export function useIsVerticalNavigationEnabled(): boolean {
    const { isFeatureEnabled } = useVerticalNavigationFeature();
    return isFeatureEnabled;
}
export function useVerticalNavigationFeature() {
    const [isVerticalNavigationDisabled, setIsVerticalNavigationDisabled] = useUIOnlyFeatureFlag("Disable Vertical Navigation", { isInitiallyEnabled: false, scope: "machine" });
    const isOctopusFeatureToggleEnabled = useOctopusFeatureToggle("vertical-navigation", false);
    const isFeatureEnabled = !isVerticalNavigationDisabled && (Environment.isInDevelopmentMode() || isOctopusFeatureToggleEnabled);
    return {
        isFeatureEnabled,
        isUIFeatureFlagEnabled: isVerticalNavigationDisabled,
        showUIFeatureFlagToggle: true, // allows even customers with the Octopus feature toggle enabled to disable vertical navigation
        setUIFeatureFlagEnabled: setIsVerticalNavigationDisabled,
    };
}
