import { OctopusError } from "@octopusdeploy/octopus-server-client";
export interface ErrorDetails {
    [key: string]: string;
}
export interface Errors {
    message: string;
    errors: string[];
    statusCode?: number;
    parsedHelpLinks?: string[];
    helpText?: string;
    helpLink?: string;
    fullException?: string;
    /**
     * Depending on how the Errors object was created, this may not contain any fieldErrors
     * We plan to remove fieldErrors from this generic Errors object at some point,
     * and find an alternative method of accessing the fieldErrors that is more tightly coupled to Forms.
     */
    fieldErrors: {
        [other: string]: string;
    };
    details?: ErrorDetails;
}
export function isErrors(object: unknown): object is Errors {
    return Object.prototype.hasOwnProperty.call(object, "message") && Object.prototype.hasOwnProperty.call(object, "errors") && Object.prototype.hasOwnProperty.call(object, "fieldErrors");
}
export interface FieldErrors {
    [key: string]: string;
}
export interface ErrorActions {
    getFieldError: (fieldName: string) => string;
    setError: (message: string, errors: string[], fieldErrors: FieldErrors) => void;
    setOctopusError: (error: OctopusError, map?: (error: Errors) => Errors) => void;
}
export function getErrorsFromError(error: Error): Errors {
    // For some reason, webstorm thinks that the following type guard is unsound, even though OctopusError inherits from Error
    // noinspection SuspiciousTypeOfGuard
    if (error instanceof OctopusError) {
        return createErrorsFromOctopusError(error);
    }
    return {
        message: error.message,
        fieldErrors: {},
        errors: error.stack?.split(" at ") ?? [],
    };
}
export function createErrorsFromOctopusError(err: OctopusError): Errors {
    return {
        message: err.ErrorMessage,
        errors: err.Errors || [],
        parsedHelpLinks: err.ParsedHelpLinks,
        helpLink: err.HelpLink,
        helpText: err.HelpText,
        fullException: err.FullException,
        fieldErrors: {},
        statusCode: err.StatusCode,
        details: err.Details,
    };
}
