import type { KubernetesServiceProperties } from "@octopusdeploy/legacy-action-properties";
import type { KubernetesServerSideApplyProperties } from "@octopusdeploy/legacy-action-properties/src/KubernetesServerSideApplyProperties";
import { ActionExecutionLocation } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import * as React from "react";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import type { ActionSummaryProps } from "~/components/Actions/actionSummaryProps";
import { kubernetesMixedExecutionLocationConfig } from "~/components/Actions/kubernetes/kubernetesMixedExecutionLocationConfig";
import { KubernetesServiceComponent } from "~/components/Actions/kubernetes/kubernetesServiceComponent";
import KubernetesStatusCheckComponent, { InitialStatusCheckWithTimeoutProperties } from "~/components/Actions/kubernetes/kubernetesStatusCheckComponent";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import pluginRegistry from "~/components/Actions/pluginRegistry";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import StringKeyValueEditList from "~/components/EditList/KeyValueEditList";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import { FormSectionHeading } from "~/components/form";
import { default as ExpandableFormSection } from "~/components/form/Sections/ExpandableFormSection";
import Summary from "~/components/form/Sections/Summary";
import Note from "~/primitiveComponents/form/Note/Note";
import { toBoolString } from "~/utils/fieldConverters";
import { JsonUtils } from "~/utils/jsonUtils";
import { getKubernetesTargetDiscoveryCloudProviders } from "./getKubernetesTargetDiscoveryCloudProviders";
import KubernetesNamespaceFormSection from "./kubernetesNamespaceFormSection";
import { ServerSideApplyFormSection } from "./serverSideApplyFormSection";
class KubernetesDeployServiceActionSummary extends BaseComponent<ActionSummaryProps, never> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }
    render() {
        return <div>Deploy a service resource to Kubernetes</div>;
    }
    static displayName = "KubernetesDeployServiceActionSummary";
}
export interface KubernetesDeployServiceActionProperties extends KubernetesServiceProperties, KubernetesServerSideApplyProperties {
}
type KubernetesDeployServiceActionEditProps = ActionEditProps<KubernetesDeployServiceActionProperties>;
class KubernetesDeployServiceActionEdit extends BaseComponent<KubernetesDeployServiceActionEditProps, never> {
    constructor(props: ActionEditProps<KubernetesDeployServiceActionProperties>) {
        super(props);
    }
    async componentDidMount() {
        if (this.props.properties["Octopus.Action.Kubernetes.ServerSideApply.Enabled"] === undefined) {
            this.setDefaultServerSideApply();
        }
    }
    private setDefaultServerSideApply() {
        this.props.setProperties({ ["Octopus.Action.Kubernetes.ServerSideApply.Enabled"]: "True" });
        this.props.setProperties({ ["Octopus.Action.Kubernetes.ServerSideApply.ForceConflicts"]: "True" });
    }
    render() {
        return (<div>
                <KubernetesStatusCheckComponent jobsSupported={false} timeoutSupported={true} statusCheckSupported={true} showLegacyWait={false} properties={this.props.properties} packages={this.props.packages} plugin={this.props.plugin} errors={this.props.errors} busy={this.props.busy} expandedByDefault={this.props.expandedByDefault} getFieldError={this.props.getFieldError} setProperties={this.props.setProperties} setPackages={this.props.setPackages} doBusyTask={this.props.doBusyTask}/>
                <KubernetesServiceComponent properties={this.props.properties} packages={this.props.packages} plugin={this.props.plugin} getFieldError={this.props.getFieldError} setProperties={this.props.setProperties} setPackages={this.props.setPackages} doBusyTask={this.props.doBusyTask} busy={this.props.busy} errors={this.props.errors} projectId={this.props.projectId} gitRef={this.props.gitRef} expandedByDefault={this.props.expandedByDefault} importLabels={true}/>
                <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.DeploymentLabels" isExpandedByDefault={this.props.expandedByDefault} title="Service Labels" summary={this.labelsSummary()} help={"The labels applied to the service resource"}>
                    <Note>Labels are optional name/value pairs that are assigned to the Service resource.</Note>
                    <Note>
                        Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-service#service-labels">service labels</ExternalLink>.
                    </Note>
                    <StringKeyValueEditList items={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"]} name="Label" separator="=" onChange={(val) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentLabels"]: val })} valueLabel="Value" keyLabel="Name" hideBindOnKey={false} projectId={this.props.projectId} gitRef={this.props.gitRef}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.SelectorLabels" isExpandedByDefault={this.props.expandedByDefault} title="Service Selector Labels" summary={this.selectorLabelsSummary()} help={"The selector labels used to match pod resources"}>
                    <Note>Service selector labels are used to match the pods that traffic will be sent to.</Note>
                    <Note>
                        Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-service#service-selector-labels">service selector labels</ExternalLink>.
                    </Note>
                    <StringKeyValueEditList items={this.props.properties["Octopus.Action.KubernetesContainers.SelectorLabels"]} name="Selector Label" separator="=" onChange={(val) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.SelectorLabels"]: val })} valueLabel="Value" keyLabel="Name" hideBindOnKey={false} projectId={this.props.projectId} gitRef={this.props.gitRef}/>
                </ExpandableFormSection>
                <KubernetesNamespaceFormSection namespace={this.props.properties["Octopus.Action.KubernetesContainers.Namespace"]} onChange={(ns) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.Namespace"]: ns })}/>

                <FormSectionHeading title="Additional Configuration Options"/>
                <ServerSideApplyFormSection enabled={this.props.properties["Octopus.Action.Kubernetes.ServerSideApply.Enabled"] === "True"} forceConflicts={this.props.properties["Octopus.Action.Kubernetes.ServerSideApply.ForceConflicts"] === "True"} onEnabledChange={(value) => this.props.setProperties({ ["Octopus.Action.Kubernetes.ServerSideApply.Enabled"]: toBoolString(value) })} onForceConflictsChange={(value) => this.props.setProperties({ ["Octopus.Action.Kubernetes.ServerSideApply.ForceConflicts"]: toBoolString(value) })}></ServerSideApplyFormSection>
            </div>);
    }
    private selectorLabelsSummary() {
        const labels = _.toPairs(JsonUtils.tryParse(this.props.properties["Octopus.Action.KubernetesContainers.SelectorLabels"], {}));
        if (labels.length === 0) {
            return Summary.placeholder("The service resource selector labels");
        }
        return Summary.summary(<span>
                Selecting pod resources with the label{labels.length > 1 && <span>s</span>}{" "}
                {_.chain(labels)
                .flatMap((pair) => [
                <strong>
                            {pair[0]}: {pair[1]}
                        </strong>,
                <span>, </span>,
            ])
                .slice(0, -1)
                .value()}
            </span>);
    }
    private labelsSummary() {
        const labels = _.toPairs(JsonUtils.tryParse(this.props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {}));
        if (labels.length === 0) {
            return Summary.placeholder("The service resource labels");
        }
        return Summary.summary(<span>
                Add label{labels.length > 1 && <span>s</span>}{" "}
                {_.chain(labels)
                .flatMap((pair) => [
                <strong>
                            {pair[0]}: {pair[1]}
                        </strong>,
                <span>, </span>,
            ])
                .slice(0, -1)
                .value()}
            </span>);
    }
    static displayName = "KubernetesDeployServiceActionEdit";
}
pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.KubernetesDeployService",
    summary: (properties, targetRolesAsCSV) => <KubernetesDeployServiceActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV}/>,
    editSections: {
        top: (props: KubernetesDeployServiceActionEditProps) => <KubernetesDeployServiceActionEdit {...props}/>,
        default: (props: KubernetesDeployServiceActionEditProps) => <></>,
    },
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Required,
    hasPackages: (action) => false,
    targetDiscoveryCloudConnectionProviders: getKubernetesTargetDiscoveryCloudProviders,
    getInitialProperties: () => InitialStatusCheckWithTimeoutProperties,
    canUseExecutionTimeouts: false,
    mixedExecutionLocations: kubernetesMixedExecutionLocationConfig,
    disableInlineExecutionContainers: true,
});
