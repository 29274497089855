import { css } from "@emotion/css";
import { EmptyStateDashboardIllustration, EmptyStateDashboardNoPermissionIllustration, NavigationButton, NavigationButtonType } from "@octopusdeploy/design-system-components";
import type { SimpleMenuItem, PrimaryPageAction, PageAction } from "@octopusdeploy/design-system-components";
import { text } from "@octopusdeploy/design-system-tokens";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import { Level1EmptyStateLayout } from "~/components/GettingStarted/Level1EmptyStateLayout";
import ExternalVideoLink from "~/components/Navigation/ExternalLink/ExternalVideoLink";
import { PermissionCheck } from "~/components/PermissionCheck";
import GettingStartedButton from "~/components/ProjectBasedActivation/GettingStartedButton";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import type { DoBusyTask } from "../DataBaseComponent";
import ContinueButton from "./ContinueButton";
interface OnboardingProject {
    Name: string;
    Slug: string;
}
interface LegacyDashboardOnboardingLayoutProps {
    spaceId: string;
    canContinueOnboarding: boolean;
    onboardingProject?: OnboardingProject;
    header?: {
        title: string;
        primaryAction?: PrimaryPageAction;
        actions?: PageAction[];
        overflowActions?: SimpleMenuItem[];
    };
    busy: Promise<void> | undefined;
    doBusyTask: DoBusyTask;
}
function LegacyDashboardOnboardingLayout({ spaceId, canContinueOnboarding, onboardingProject, header, busy, doBusyTask }: LegacyDashboardOnboardingLayoutProps) {
    const onboardingTitle = isOnboardingHalfDone(canContinueOnboarding, onboardingProject) ? "Continue your project setup" : "Deploy your first application";
    const onboardingIntro = isOnboardingHalfDone(canContinueOnboarding, onboardingProject) ? (<span>
            <strong>{onboardingProject.Name}</strong> is almost setup
        </span>) : ("We'll guide you along the process of adding your first project through to releasing your first deployment.");
    const learnMoreLink = (<ExternalVideoLink href="OnboardingGettingStartedVideo" className={learnMoreLinkStyles}>
            Watch the video (3 mins)
        </ExternalVideoLink>);
    const isKubernetesGuidedSetupFeatureEnabled = useOctopusFeatureToggle("kubernetes-guided-setup", false);
    const onboardingActions = isOnboardingHalfDone(canContinueOnboarding, onboardingProject) ? [ContinueProjectSetup(isKubernetesGuidedSetupFeatureEnabled, spaceId, onboardingProject, busy, doBusyTask)] : [<GettingStartedButton />, learnMoreLink];
    return (<PermissionCheck permission={Permission.ProjectCreate} alternate={<NoActionDashboard />}>
            <Level1EmptyStateLayout title={onboardingTitle} intro={onboardingIntro} actionButtonsAndLinks={onboardingActions} image={<EmptyStateDashboardIllustration />} analyticLocation={"Onboarding dashboard"} header={header}/>
        </PermissionCheck>);
}
function ContinueProjectSetup(isKGSEnabled: boolean, spaceId: string, project: OnboardingProject, busy: Promise<void> | undefined, doBusyTask: DoBusyTask) {
    return isKGSEnabled ? (<ContinueButton spaceId={spaceId} projectSlug={project.Slug} busy={busy} doBusyTask={doBusyTask}/>) : (<NavigationButton label="Continue" href={links.deploymentsPage.generateUrl({ spaceId, projectSlug: project.Slug }, { configureProject: true })} type={NavigationButtonType.Primary}/>);
}
function isOnboardingHalfDone(canContinueOnboarding: boolean, onboardingProject: OnboardingProject | undefined): onboardingProject is OnboardingProject {
    return canContinueOnboarding && onboardingProject !== undefined;
}
function NoActionDashboard() {
    return (<Level1EmptyStateLayout title={"Permissions"} intro={"No projects have been setup yet. Your current permissions don\u2019t allow you to create projects. If you require more information or believe this is in error please contact your administrator. "} image={<EmptyStateDashboardNoPermissionIllustration />}/>);
}
const learnMoreLinkStyles = css({
    font: text.link.default.medium,
});
export default LegacyDashboardOnboardingLayout;
