import { Checkbox } from "@octopusdeploy/design-system-components";
import { PriorityMode } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ExpandableFormSection from "../../../../../components/form/Sections/ExpandableFormSection";
import Summary from "../../../../../components/form/Sections/Summary";
interface PriorityOptionsProps {
    priority: PriorityMode;
    onChange(value: boolean): void;
}
const PriorityOptions = (props: PriorityOptionsProps) => {
    return (<ExpandableFormSection title="Priority" errorKey="priority" help="Choose whether to deploy with priority" summary={buildSummary(props.priority)}>
            <div>
                <Checkbox label="Priority" value={props.priority === PriorityMode.On} onChange={props.onChange}/>
            </div>
        </ExpandableFormSection>);
    function buildSummary(priorityMode: PriorityMode) {
        const summary = priorityMode === PriorityMode.On ? Summary.summary("Deploy with priority") : Summary.default("No priority");
        return summary;
    }
};
export default PriorityOptions;
