/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { css } from "@emotion/css";
import type { MenuTargetAriaAttributes, SimpleMenuItem } from "@octopusdeploy/design-system-components";
import { dividerMenuItem, NavigationBar, SimpleMenu, Tooltip, useMenuState } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { UserResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { OctoLink } from "@octopusdeploy/utilities";
import type { MouseEventHandler } from "react";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHiddenFeatures } from "~/areas/configuration/components/FeaturesLayout/useHiddenFeatures";
import { repository, session } from "~/clientInstance";
import { authRoutes } from "~/components/AuthenticationRoutes/authRoutes";
import { useAnalyticsWithMenuExternalLink } from "~/components/Navigation/ExternalLink/useExternalLinksWithAnalytics";
import { useDashboardVNextFeature } from "~/components/RootRoutes/useIsDashboardVNextEnabled";
import { useVerticalNavigationFeature } from "~/components/RootRoutes/useIsVerticalNavigationEnabled";
import type { SpaceContext } from "~/components/SpaceLoader/SpaceLoader";
import { useSetThemePaletteType, useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import { Avatar } from "~/primitiveComponents/dataDisplay/Avatar/Avatar";
import type { GlobalState } from "~/store/reducers";
interface ExposedUserAccountMenuProps {
    currentUser: UserResource;
    spaceContext: SpaceContext;
}
export function UserAccountMenu({ currentUser, spaceContext }: ExposedUserAccountMenuProps) {
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    const userAccountMenuItems = useUserAccountMenuItems(currentUser, spaceContext);
    return (<div>
            <OpenUserAccountMenuButton currentUser={currentUser} onClick={openMenu} ariaAttributes={buttonAriaAttributes} isMenuOpen={menuState.isOpen}/>
            <SimpleMenu accessibleName={"User account"} items={userAccountMenuItems} menuState={menuState} anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
        }}/>
        </div>);
}
interface OpenUserAccountMenuButtonProps {
    onClick: MouseEventHandler;
    currentUser: UserResource;
    ariaAttributes: MenuTargetAriaAttributes;
    isMenuOpen: boolean;
}
export function OpenUserAccountMenuButton({ onClick, currentUser, ariaAttributes, isMenuOpen }: OpenUserAccountMenuButtonProps) {
    return (<Tooltip content={currentUser.DisplayName}>
            <NavigationBar.IconButton onClick={onClick} accessibleName={isMenuOpen ? "Hide user account menu" : "Show user account menu"} icon={<UserAvatar user={currentUser} size={32}/>} {...ariaAttributes}/>
        </Tooltip>);
}
export function UserAvatar({ user, size }: {
    user: UserResource;
    size: number;
}) {
    return <Avatar avatarLink={user.Links?.Avatar} isService={user?.IsService} size={size}/>;
}
export function useUserAccountMenuItems(currentUser: UserResource, spaceContext: SpaceContext): SimpleMenuItem[] {
    const userDisplayNameMenuItem = createUserDisplayNameMenuItem(currentUser);
    const darkThemeMenuItem = useDarkThemeMenuItem();
    const featureFlags = useFeatureFlags();
    const downloadLink = createDownloadLinkMenuItem();
    const profileLink = createProfileLink(spaceContext);
    const recentChangesMenuItem = useRecentChangesMenuItem();
    const helpItems = useHelpLinkItems();
    return [userDisplayNameMenuItem, dividerMenuItem, downloadLink, recentChangesMenuItem, dividerMenuItem, darkThemeMenuItem, ...featureFlags, dividerMenuItem, profileLink, dividerMenuItem, signOutLink, ...helpItems];
}
function createProfileLink(spaceContext: SpaceContext): SimpleMenuItem {
    const meLink = links.currentUserRootRedirect.generateUrl();
    return {
        type: "internal-link",
        label: "My profile",
        path: meLink,
        showAsActive: "never",
    };
}
function createUserDisplayNameMenuItem(user: UserResource): SimpleMenuItem {
    return {
        type: "information",
        content: (<div className={userDisplayNameContainerStyles}>
                <UserAvatar user={user} size={40}/>
                {user.DisplayName}
            </div>),
    };
}
const userDisplayNameContainerStyles = css({
    display: "flex",
    gap: space[16],
    alignItems: "center",
});
const signOutLink: SimpleMenuItem = { type: "internal-link", label: "Sign out", path: authRoutes.signOut, showAsActive: "never" };
function createDownloadLinkMenuItem(): SimpleMenuItem {
    const version = repository.getServerInformation().version;
    const [major, minor, ...rest] = version.split(".");
    const build = rest.join(".");
    return {
        type: "external-link",
        label: `v${major}.${minor} (Build ${build})`,
        href: `https://octopus.com/downloads/${version}`,
    };
}
function useDarkThemeMenuItem(): SimpleMenuItem {
    const currentPalette = useThemePaletteType();
    const setPalette = useSetThemePaletteType();
    const toggleIsDarkModeEnabled = useCallback((isDarkModeEnabled: boolean) => setPalette(isDarkModeEnabled ? "dark" : "light"), [setPalette]);
    const isDarkModeEnabled = currentPalette === "dark";
    return useMemo(() => ({
        type: "toggle",
        label: "Dark theme",
        isEnabled: isDarkModeEnabled,
        setIsEnabled: toggleIsDarkModeEnabled,
    }), [isDarkModeEnabled, toggleIsDarkModeEnabled]);
}
function useFeatureFlags(): SimpleMenuItem[] {
    const hiddenFeaturesEnabled = useHiddenFeatures();
    const { showUIFeatureFlagToggle: showDisableVerticalNavigationToggle, isUIFeatureFlagEnabled: isVerticalNavigationDisabled, setUIFeatureFlagEnabled: setIsVerticalNavigationDisabled } = useVerticalNavigationFeature();
    const { showUIFeatureFlagToggle: showDisableDashboardVNextToggle, isUIFeatureFlagEnabled: isDashboardVNextDisabled, setUIFeatureFlagEnabled: setIsDashboardVNextDisabled } = useDashboardVNextFeature();
    return useMemo(() => {
        if (!hiddenFeaturesEnabled)
            return [];
        const features: SimpleMenuItem[] = [];
        if (showDisableVerticalNavigationToggle) {
            features.push({ type: "toggle", label: "Disable vertical navigation", isEnabled: isVerticalNavigationDisabled, setIsEnabled: setIsVerticalNavigationDisabled });
        }
        if (showDisableDashboardVNextToggle) {
            features.push({ type: "toggle", label: "Disable dashboard vnext", isEnabled: isDashboardVNextDisabled, setIsEnabled: setIsDashboardVNextDisabled });
        }
        return features;
    }, [hiddenFeaturesEnabled, showDisableVerticalNavigationToggle, showDisableDashboardVNextToggle, isVerticalNavigationDisabled, setIsVerticalNavigationDisabled, isDashboardVNextDisabled, setIsDashboardVNextDisabled]);
}
function useRecentChangesMenuItem(): SimpleMenuItem {
    const host = "http://octopus.com/releasenotes";
    const installationHistory = session.installationHistory;
    const queryParams = new URLSearchParams();
    const installationsToSend = Math.min(4, installationHistory.length);
    installationHistory.slice(0, installationsToSend).forEach((i) => {
        queryParams.append("versions", i.Version);
        queryParams.append("installedAt", i.InstalledAt);
    });
    const externalLink: SimpleMenuItem = { type: "external-link", label: "Release notes", href: `${host}?${queryParams.toString()}` };
    return useAnalyticsWithMenuExternalLink(externalLink);
}
function useHelpLinkItems(): SimpleMenuItem[] {
    const isHelpSidebarEnabled = useSelector((state: GlobalState) => state.configurationArea.features.isHelpSidebarEnabled);
    const isNarrowScreen = useMediaQuery({ query: `(max-width: 811px)` });
    const customHelpSidebarSupportLink = useSelector((state: GlobalState) => state.configurationArea.features && state.configurationArea.features.helpSidebarSupportLink);
    const customHelpSidebarSupportLinkLabel = useSelector((state: GlobalState) => state.configurationArea.features && state.configurationArea.features.helpSidebarSupportLinkLabel);
    const helpSupportLink = customHelpSidebarSupportLink || OctoLink.Create("HelpGeneral");
    let helpSupportLinkLabel = "Octopus support";
    // customHelpSidebarSupportLinkLabel requires customHelpSidebarSupportLink to exist otherwise it will use the default Octopus support
    if (customHelpSidebarSupportLink) {
        helpSupportLinkLabel = customHelpSidebarSupportLinkLabel || "Your organization's internal support";
    }
    if (!isHelpSidebarEnabled || isNarrowScreen) {
        return [dividerMenuItem, { type: "external-link", label: helpSupportLinkLabel, href: helpSupportLink }];
    }
    return [];
}
